import React, { useEffect, useCallback, useRef, useState } from "react";
import { NavLink, useLocation } from "react-router-dom";
import { useParams } from "react-router-dom";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import Axios from "axios";
import toastr from "toastr";
import 'toastr/build/toastr.css';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css'; // Import the styles
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";

// import required modules

import { Autoplay } from "swiper/modules";
// import required modules
import { Navigation } from "swiper/modules";
import Header from "../Header/Header";
const Handpainting = () => {
    const { id } = useParams();
    const [framedatadata, setFramedata] = useState("");
    const [frameId, setFramId] = useState("");
    const [frameCateId, setFrameCateId] = useState("");
    const [isReq, setIsReq] = useState(false);
    const [selectedSize, setSelectedSize] = useState(null);
    const [allframes, setAllframes] = useState([]);
    const [allSaveFrames, setallSaveFrames] = useState([]);
    const modalRef = useRef(null);
    const [frameSizes, setFrameSizes] = useState([]);
    const [framSize, setFrameSize] = useState([]);
    const [currentIndex, setCurrentIndex] = useState(0);
    const [imageScroll, setImagesSroll] = useState([]);
    const [isFavorited, setIsFavorited] = useState(false);
    const [isOffsetSelected, setIsOffSet] = useState(true);
    const location = useLocation();
    const scrollToHash = useCallback(() => {
        const hash = location.hash;
        if (hash) {
            const section = document.querySelector(hash);
            section?.scrollIntoView({ behavior: "smooth", block: "start" });
        } else {
            window.scroll({
                top: 0,
                left: 0,
                behavior: "smooth",
            });
        }
    }, [location.hash]);

    useEffect(() => {
        framemaindata(id);
        scrollToHash();
    }, [scrollToHash]);
    useEffect(() => {
        let images = [];
        // setImagesSroll(images);
    }, []);
    function framedata(frameimages) {

        setImagesSroll(frameimages);
    }

    const showImage = (index) => {

        setCurrentIndex(index);
    };

    const previousImage = () => {
        setCurrentIndex(
            (prevIndex) => (prevIndex - 1 + imageScroll.length) % imageScroll.length
        );
    };

    const [formData, setFormData] = useState({
        name: '',
        email: '',
        phone: '',
        description: '',
    });

    // Event handler for input changes
    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value,
        });
    };

    // Event handler for form submission
    const handleSubmit = (e) => {
        e.preventDefault();
        // Access the form data in 'formData'
        console.log(formData);
        // Add your logic for form submission here
    };


    const nextImage = () => {
        setCurrentIndex((prevIndex) => (prevIndex + 1) % imageScroll.length);
    };
    function getImageType(image) {
        // Check if the image URL ends with ".mp4"
        return image.toLowerCase().endsWith(".mp4");
    }
    function framemaindata(frameId) {
        scrollToHash();
        const url =
            "https://aanvikshikiartapi.aanvikshiki.art/api/frame/getFrame/" + frameId;

        const headerTypes = {
            "content-type": "application/json",
        };
        Axios.get(url, { headers: headerTypes })
            .then((res) => {

                const modifiedArray = res.data[0].base64Images.map((url) => ({ image: url }));
                modifiedArray.forEach((obj) => {
                    obj.imageType = getImageType(obj.image);
                });
                console.log(modifiedArray)
                res.data[0].base64Images = modifiedArray
                setFramedata(res.data[0]);

                if (res.data[0].categories === 'TANJORE PAINTINGS') {
                    setIsReq(false)
                } else {
                    setIsReq(true)
                }
                setFrameCateId(res.data[0].categoryId)
                setFramId(res.data[0].categoryId)
                getFeatureList(frameId, res.data[0].categoryId)

                // const modifiedBase64Array = addPrefixToBase64Array(
                //   res.data[0].base64Images
                // );
                // framedata(modifiedBase64Array);

                framedata(res.data[0].base64Images);
                getFav(frameId)
            })
            .catch((error) => {
                console.log(error);
            });
    }

    function getFav(frameId) {

        const url =
            `https://aanvikshikiartapi.aanvikshiki.art/api/frame/getFav?frameId=${frameId}`;

        const headerTypes = {
            "content-type": "application/json",
        };
        const authToken = localStorage.getItem("token");

        const config = {
            Authorization: authToken,
        };

        Axios.get(url, { headers: config })
            .then((res) => {
                setIsFavorited(res.data.favourite);

            })
            .catch((error) => {
                console.log(error);
            });
    }

    function addPrefixToBase64Array(base64Array) {
        // Map over the array and perform the required operations
        const resultArray = base64Array.map((base64) => {
            // Step 1: Decode the Base64 content
            const decodedData = atob(base64);

            // Step 2: Prepend "info/" to the decoded content
            const modifiedData = "data:image/jpeg;base64/" + decodedData;

            // Step 3 (optional): Encode the modified content back to Base64
            const modifiedBase64 = btoa(modifiedData);
            const modifiedBase = "data:image/jpeg;base64" + modifiedBase64;

            return modifiedBase; // You can also return modifiedData if Base64 encoding is not needed.
        });

        return resultArray;
    }

    const handleNameChange = (event) => {
        const { value } = event.target;
        setFormData({
            ...formData,
            name: value
        });
    };

    const handlePhoneChange = (event) => {
        const { value } = event.target;
        setFormData({
            ...formData,
            phoneNumber: value
        });
    };

    const handleEmailChange = (event) => {
        const { value } = event.target;
        setFormData({
            ...formData,
            email: value
        });
    };

    const handleDescriptionChange = async (event) => {
        const { value } = event.target;
        setFormData({
            ...formData,
            description: value
        });
    };

    const handleFormSubmit = async (event) => {
        event.preventDefault();
        console.log(formData);

        try {
            const response = await fetch('https://aanvikshikiartapi.aanvikshiki.art/api/user/scheduleAppointment', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(formData),
            });

            if (response.ok) {

                toastr.success("Email Sent sucessful")
                // alert('Form submitted successfully!');
                modalRef.current.click();
            } else {
                alert('Error submitting form');
                console.error('Error:', response.statusText);
            }
        } catch (error) {

            // alert('Error submitting form');
            toastr.eror(error)
            console.error('Error:', error);
        }

    }
    useEffect(() => {
        // api call
        const url = "https://aanvikshikiartapi.aanvikshiki.art/api/frame/allframes";

        const headerTypes = {
            "content-type": "application/json",
        };
        Axios.get(url, { headers: headerTypes })
            .then((res) => {
                // console.log(res.data);
                setAllframes(res.data);
            })
            .catch((error) => {
                console.log(error);
            });
    }, []);


    // add favorites api
    const favoritedata = (e) => {

        e.preventDefault();
        const authToken = localStorage.getItem("token");
        const userId = localStorage.getItem("userId");
        const frameId = framedatadata._id;
        const body = {
            userId: userId,
            frameId: frameId,
        };

        const apiUrl =
            "https://aanvikshikiartapi.aanvikshiki.art/api/frame/addfavourite";

        // Retrieve the token from local storage

        if (authToken) {
            const config = {
                headers: {
                    Authorization: authToken,
                },
            };

            Axios.post(apiUrl, body, config)
                .then((res) => {
                    console.log(res);

                    toastr.success(res.data.message)
                    console.log(res.data.message);
                    if (isFavorited === false) {
                        setIsFavorited(true);
                    }
                    if (isFavorited === true) {
                        setIsFavorited(false);
                    }

                    getFav(frameId)

                })
                .catch((err) => {
                    console.error("Error adding address:", err);
                    // Handle the error, display an error message, or perform other error-handling actions.
                });
        } else {
            alert('Please login')
            // Handle the case where the token is not available (user is not authenticated)
        }
    };

    // add to bag post api
    const getFeatureList = (frameId, data) => {

        const authToken = localStorage.getItem("token");
        const apiUrl =
            `https://aanvikshikiartapi.aanvikshiki.art/api/frame/getFeatureList?categoryId=${data}`;

        // Retrieve the token from local storage

        if (authToken) {
            const config = {
                headers: {
                    Authorization: authToken,
                },
            };
            Axios.get(apiUrl, config)
                .then((res) => {

                    let information = []
                    console.log('resresres', res);
                    res.data.forEach((value) => {
                        if (value.categoryId === data) {
                            information.push(value)
                        }
                    })
                        ;
                    getFrameSizesByCategoryId(frameId, res.data[0]._id)
                    setallSaveFrames(information)
                })
                .catch((err) => {
                    console.error("Error adding address:", err);
                    // Handle the error, display an error message, or perform other error-handling actions.
                });
        } else {
            // Handle the case where the token is not available (user is not authenticated)
        }
    };
    const getFrameSizesByCategoryId = (frameId, CategoryFeatureId) => {

        const authToken = localStorage.getItem("token");
        const apiUrl =
            `https://aanvikshikiartapi.aanvikshiki.art/api/frame/getFrameSizesByCategoryId?frameId=${id}&CategoryFeatureId=${CategoryFeatureId}`;
        // Retrieve the token from local storage
        if (authToken) {
            const config = {
                headers: {
                    Authorization: authToken,
                },
            };

            Axios.get(apiUrl, config)
                .then((res) => {
                    let information = []
                    console.log('resresres', res);
                    // res.data.forEach((value) => {
                    //   // setFrameSizes = value
                    //   if (value.categoryId === data) {
                    //     information.push(value)
                    //   }
                    // })
                    // setFrameSizes(res.data)
                    setFrameSize(res.data)
                })
                .catch((err) => {
                    console.error("Error adding address:", err);
                    // Handle the error, display an error message, or perform other error-handling actions.
                });
        } else {
            // Handle the case where the token is not available (user is not authenticated)
        }
    };
    const addtobag = (e) => {
        e.preventDefault();
        if (selectedSize) {
            const userId = localStorage.getItem("userId");
            const frameId = id;
            const categoryId = framedatadata.categoryId;
            const sizeid = selectedSize;
            const apiUrl =
                "https://aanvikshikiartapi.aanvikshiki.art/api/user/addCart";
            // Retrieve the token from local storage
            const authToken = localStorage.getItem("token");
            <Header datas={authToken} />
            if (authToken) {
                const config = {
                    headers: {
                        Authorization: authToken,
                    },
                };
                const body = {
                    userId: userId,
                    frameId: frameId,
                    categoryId: frameCateId,
                    sizeid: sizeid,
                };

                Axios.post(apiUrl, body, config)
                    .then((res) => {
                        console.log(res);
                        toastr.success(res.data.message)
                    })
                    .catch((err) => {
                        console.error("Error adding frame to cart:", err);
                        // Handle the error, display an error message, or perform other error-handling actions.
                    });
            } else {
                // Handle the case where the token is not available (user is not authenticated)
            }
        } else {
            alert("Please select size");
        }
    };
    const handleSizeSelect = (sizeId) => {
        setSelectedSize(sizeId);
        framSize.forEach((value) => {
            if (value._id === sizeId) {
                setFramedata({ price: value.price })
            }
        })
        // setFramedata({price: 123})
    };
    const CheckSizeSelect = (sizeId) => {

        if (sizeId === '655347546c26267c5a68a7cc') {
            setIsOffSet(true)
        }
        else {
            setIsOffSet(false)
        }
        console.log(sizeId)
        console.log('frame Id', framedatadata._id)
        getFrameSizesByCategoryId(framedatadata._id, sizeId)
    };

    return (
        <>
            <div className="cardinnerpage-content">
                <div className="innerpage-top">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="edit-page5">
                                <div className="container-fluid">
                                    <h3 className="page-title">
                                        <NavLink className="page-titlespan" to="/landing">
                                            {" "}
                                            Home <span className="arrow">{">"}</span> {" "}
                                        </NavLink>{" "}
                                        <NavLink className="page-titlespan" to="/landing">
                                            {" "}
                                            HAND PAINTING  <span className="arrow">{">"}</span> {" "}
                                        </NavLink>
                                        <span className="price-title">
                                                    {framedatadata?.title}
                                                </span>                                    </h3>
                                    <div className="row">
                                        <div className="col-md-7">
                                            <div className="gallery">
                                                {/* <div className="thumbnails"> */}
                                                {/* {imageScroll.map((item, index) => (
                              <div className="image-inside" key={index}> */}
                                                {/* <img
                                  className="thumbnail"
                                  src="./images/landing/recomende-img1.png"
                                  onClick={() => showImage(index)}
                                  alt={`Thumbnail ${index + 1}`}
                                /> */}
                                                {/* {item.imageType ? (
                                  <video onClick={() => showImage(index)} className="thumbnail"  >
                                    <source src={item.image} type="video/mp4" />
                                    Your browser does not support the video tag.
                                  </video>
                                ) : (
                                  <img src={item.image} onClick={() => showImage(index)} className="thumbnail" alt='' />
                                )}
                              </div>
                            ))} */}
                                                {/* </div> */}
                                                <div className="main-image main-image-kalyanam">
                                                    {/* <i
                              className="fas fa-chevron-left prev-icon"
                              onClick={previousImage}
                            /> */}
                                                    <div className="main-imagedata main-imagedata-kalyanam">
                                                        {/* <img
                                id="mainImg"
                                className="image-inside"
                                // src={imageScroll[currentIndex]}
                                src={imageScroll[currentIndex]?.image}
                                alt="no-data"
                              /> */}
                                                        {imageScroll[currentIndex]?.imageType ? (
                                                            // Display video tag if imageType is true
                                                            <video
                                                                id="mainVideo"
                                                                className="video-inside"
                                                            // Add other video attributes as needed
                                                            >
                                                                <source src={imageScroll[currentIndex]?.image} type="video/mp4" />
                                                                Your browser does not support the video tag.
                                                            </video>
                                                        ) : (
                                                            // Display image tag if imageType is false or undefined
                                                            <img
                                                                id="mainImg"
                                                                className="image-inside image-inside-kalyanam"
                                                                src={imageScroll[currentIndex]?.image}
                                                                alt="no-data"
                                                            />
                                                        )}
                                                    </div>
                                                    {/* <i
                              className="fas fa-chevron-right next-icon"
                              onClick={nextImage}
                            /> */}
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-5">
                                            <div className="editRight-Data">
                                                <span className="price-title">
                                                    {framedatadata?.title}
                                                </span>
                                                {isOffsetSelected ? (
                                                    null
                                                ) : (
                                                    <div>
                                                        <p className="price-money">
                                                            MRP : <span className="prices">₹ {framedatadata?.price}</span>
                                                        </p>
                                                        <p className="travelers-data">incl. of all taxes</p>
                                                        <p className="travelers-data">
                                                            (Also includes all applicable duties)
                                                        </p>
                                                    </div>
                                                )}
                                                {/* <h3 className="painting-size">Painting in Inches</h3> */}
                                                {/* <h3 className="painting-size">Painting in Sizes</h3> */}
                                                <form>
                                                    <div
                                                        className="btn-group btn-group-toggle painting-ul"
                                                        data-toggle="buttons"
                                                    >
                                                        {isOffsetSelected ? (
                                                            null
                                                        ) : (
                                                            <div className="btn-group btn-group-toggle painting-ul painting-list" data-toggle="buttons">
                                                                <select
                                                                    className="form-select price-selectd"
                                                                    value={selectedSize}
                                                                    onChange={(e) => handleSizeSelect(e.target.value)}
                                                                >
                                                                    <option value="" defaultChecked>Select size</option>
                                                                    {framSize &&
                                                                        framSize.map((sizes, index) => (
                                                                            <option key={index} value={sizes._id}>
                                                                                {sizes.size}
                                                                            </option>
                                                                        ))}
                                                                </select>
                                                            </div>
                                                        )}
                                                        {/* <select className="form-select price-selectd1" aria-label="Default select example">
                                <option selected="">Open this select menu</option>
                                {
                                  allSaveFrames.map((sizes, index) => (
                                    <option key={index} value={sizes._id}>
                                      {sizes.name}
                                    </option>
                                  ))}
                              </select> */}
                                                        {/* {isReq ? (
                                <select onChange={(e) => CheckSizeSelect(e.target.value)} className="form-select price-selectd1" aria-label="Default select example">
                                  <option selected="">Open this select menu</option>
                                  {allSaveFrames.map((sizes, index) => (
                                    <option key={index} value={sizes._id}>
                                      {sizes.name}
                                    </option>
                                  ))}
                                </select>
                              ) : null} */}
                                                    </div>
                                                    {isOffsetSelected ? (
                                                        <NavLink data-bs-toggle="modal" data-bs-target="#exampleModal" className="bag-btn">
                                                            Enquire now
                                                        </NavLink>
                                                    ) : (
                                                        <div>
                                                            {/* <p className="price-money">
                                  MRP : <span>₹ {framedatadata?.price}</span>
                                </p>
                                <p className="travelers-data">incl. of taxes</p>
                                <p className="travelers-data">
                                  (Also includes all applicable duties)
                                </p> */}
                                                            <p>
                                                                <NavLink className="bag-btn" onClick={addtobag}>
                                                                    Add to Bag
                                                                </NavLink>
                                                                <NavLink className="fav-btn" onClick={favoritedata}>
                                                                    <i className={isFavorited ? "fa-solid fa-heart fullheart" : "fa-regular fa-heart"}></i>
                                                                    Favorite
                                                                </NavLink>
                                                            </p>
                                                        </div>
                                                    )}
                                                    <div className="media profilemedia-data mt-1">
                                                        {/* <img
                                                            src="/images/landing/review-img.png"
                                                            className="profilemedia-img mr-3"
                                                            alt="no-data"
                                                        /> */}
                                                        {/* <div className="media-body">
                                                            <h5 className="profilemedia-title mt-0">
                                                            </h5>
                                                        </div> */}
                                                    </div>
                                                    <p className="profile-head">Discover the Divine Essence of Aanvikshiki Tanjore Paintings</p>
                                                    <p className="profile-message">In the realm of traditional art, Aanvikshiki's collection of Tanjore paintings emerges as a source of divine inspiration and cultural heritage. Each masterpiece, crafted under the guidance of the illustrious Mr. A.R. Muthukumar and his team of exceptional artisans, transcends the boundaries of conventional artistry. These creations invite you into a world rich in myth, tradition, and spiritual enlightenment, celebrating the timeless stories and deities that form the core of our heritage.</p>
                                                    <p className="profile-head">A Testament to Masterful Artistry</p>
                                                    <p className="profile-message">Mr. A.R. Muthukumar and his adept team are visionaries in their field, breathing life into each stroke and color with a profound mastery over the human anatomy. Their work captures the divine essence embedded in the rich tapestry of Tanjore art. Every painting in the Aanvikshiki collection is a testament to their unparalleled skill and dedication to perfection.</p>
                                                </form>
                                            </div>
                                        </div>
                                    </div>
                                    <h3 className="painting-description painting-description-kalyanam">Description</h3>


                                    {/* <div className="thumbnails">
                                        {imageScroll.map((item, index) => (
                                            <div className="image-inside image-inside-video" key={index}>
                                                <img
                                className="thumbnail"
                                src={src.image}
                                onClick={() => showImage(index)}
                                alt={`Thumbnail ${index + 1}`}
                              />
                                                {item.imageType ? (
                                                    <video onClick={() => showImage(index)} className="thumbnail" controls controlsList="nodownload">
                                                        <source src={item.image} type="video/mp4" />
                                                        Your browser does not support the video tag.
                                                    </video>

                                                ) : null}

                                            </div>
                                        ))}
                                    </div> */}

                                    {/* <div className="row">
                                        <div className="col-md-4">
                                            <div className="video-links">
                                            <iframe className="video-thumb"  src="https://www.youtube.com/embed/9aCC9FOAl1Q" title="Vakulamatha Unveils the Tale of Srinivasa Kalyanam: A Srinivasa Kalyanam Special - Telugu" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
                                          <p className="video-title">A Srinivasa Kalyanam Special - Telugu</p>
                                            </div>
                                            
                                        </div>
                                        <div className="col-md-4">
                                            <div className="video-links">
                                            <iframe className="video-thumb" src="https://www.youtube.com/embed/wODJwrWa_2s" title="Vakulamatha Unveils the Tale of Srinivasa Kalyanam: A Srinivasa Kalyanam Special - Hindi" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>                                            
                                            <p className="video-title">A Srinivasa Kalyanam Special - Hindi</p>
                                            </div>
                                        </div>
                                        <div className="col-md-4">
                                            <div className="video-links">
                                            <iframe className="video-thumb" src="https://www.youtube.com/embed/qGJv9sLgMEc" title="Vakulamatha Unveils the Tale of Srinivasa Kalyanam: A Srinivasa Kalyanam Special - kannada" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
                                         <p className="video-title">A Srinivasa Kalyanam Special - kannada</p>
                                            </div>
                                        </div>
                                    </div> */}
                                    {/* <p className="painting-para"> {framedatadata?.description}</p> */}
                                    <div dangerouslySetInnerHTML={{ __html: framedatadata?.description }} />
                                    <div>
                                        {/* <ReactQuill
        theme="snow"
        
        value={framedatadata?.description}
      /> */}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div>
                <div className="modal contactus-modals fade" id="exampleModal" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                    <div className="modal-dialog contactus-maindata modal-md">
                        <div className="modal-content">
                            <div className="modal-header contactus-header">
                                <h5 className="modal-title contactus-title" id="exampleModalLabel">Fill Inquiry Forms</h5>
                                <button type="button" className="close" data-dismiss="modal" aria-label="Close" ref={modalRef}>
                                    <span aria-hidden="true">×</span>
                                </button>
                            </div>
                            <div className="modal-body">
                                <form className="contactus-form" onSubmit={handleFormSubmit}>
                                    <div className="form-group">
                                        <label className="contactus-label">Full Name</label>
                                        <input type="text" className="form-control contactus-input" id="exampleInputEmail1" aria-describedby="emailHelp" onChange={handleNameChange} name="name" />
                                    </div>
                                    <div className="form-group">
                                        <label className="contactus-label">Contact No.</label>
                                        <input type="tel" className="form-control contactus-input" onChange={handlePhoneChange} name="phoneNumber" />
                                    </div>
                                    <div className="form-group">
                                        <label className="contactus-label">Email ID</label>
                                        <input type="email" className="form-control contactus-input" placeholder="Enter your mail id" onChange={handleEmailChange} name="email" />
                                    </div>
                                    <div className="form-group">
                                        <label className="contactus-label">Description</label>
                                        <textarea className="form-control contactus-input" id="exampleFormControlTextarea1" rows="3" placeholder="Description" onChange={handleDescriptionChange} name="description"></textarea>
                                    </div>

                                    <div className="getbtn-inside">
                                        <button type="submit" className="btn btn-primary get-btn">Get a Callback</button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default Handpainting;