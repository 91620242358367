import Axios from "axios";
import React, { useCallback, useEffect, useState } from "react";
import { useLocation } from "react-router";
import { format } from "date-fns";

const Orderpage = () => {

  const [allFilters, setAllFilters] = useState([]);
  const [dateFilters, setDateFilters] = useState([]);
  const [altheOrders, setAlltherData] = useState([]);
  const location = useLocation();
  
  const scrollToHash = useCallback(() => {
    const hash = location.hash;
    if (hash) {
      const section = document.querySelector(hash);
      section?.scrollIntoView({ behavior: "smooth", block: "start" });
    } else {
      window.scroll({
        top: 0,
        left: 0,
        behavior: "smooth",
      });
    }
  }, [location.hash]);
  function formatDate(dateString) {
    const date = new Date(dateString);
    return format(date, "dd-MM-yyyy");
  }

  useEffect(() => {
    scrollToHash();
  }, [scrollToHash]);


  useEffect(() => {
    getOrderDetailsById();
  }, []);
  useEffect(() => {
    getOrderDetailsUserId();
  }, []);

  function getOrderDetailsById() {

    const apiUrl =
      `https://aanvikshikiartapi.aanvikshiki.art/api/Payment/getFilter`;

    // Retrieve the token from local storage
    const authToken = localStorage.getItem("token");

    if (authToken) {
      const config = {
        headers: {
          Authorization: authToken,
        },
      };

      Axios.get(apiUrl, config)
        .then((res) => {
          console.log(res);

          let typeFiltere = []
          let timeFiltere = []
          res.data.forEach(element => {
            if (element.type === 'status') {
              typeFiltere.push(element)
              typeFiltere.reverse()
            }
            else {
              timeFiltere.push(element)
              timeFiltere.reverse()
            }
          });
          setAllFilters(typeFiltere)
          setDateFilters(timeFiltere)
        })
        .catch((err) => {
          console.error("Error contact us ", err);
          // Handle the error, display an error message, or perform other error-handling actions.
        });
    } else {
      // Handle the case where the token is not available (user is not authenticated)
    }
  };

  function getOrderDetailsUserId() {
    let userId = localStorage.getItem("userId");
    const authToken = localStorage.getItem("token");

    if (!userId || !authToken) {
      // Handle the case where "userId" or "token" is not available in local storage
      console.error("User ID or token is missing in local storage");
      return;
    }

    const apiUrl = `https://aanvikshikiartapi.aanvikshiki.art/api/Payment/getOrder?id=${userId}`;

    const config = {
      headers: {
        Authorization: authToken,
      },
    };

    return Axios.get(apiUrl, config)
      .then((res) => {
        
        console.log(res.data);
        res.data.forEach((value) => {
          console.log(value.frames.base64Images[0])
          value.frameImage = value.frames.base64Images[0]
        })
        setAlltherData(res.data)
        return res.data; // Return the data for further processing if needed
      })
      .catch((err) => {
        console.error("Error contacting the server: ", err);
        throw err; // Rethrow the error for further handling in the calling code
      });
  }

  let filterBox = '';
  let filterBox2 = '';
  const [boxIndex, setBoxIndex] = useState(0);
  const [boxIndexTwo, setBoxIndexTwo] = useState(0);
  function setCheckstatus(e, code, status, index) {
    console.log(e);
    console.log(code);
    filterBox = code;
    setBoxIndex(index)
    functionfinalFIlter()

    // if (e === true) {
    //   filterBox.push(
    //     code
    //   );

    // } else {
    //   // Remove the specific code from the array
    //   const index = filterBox.indexOf(code);
    //   if (index > -1) {
    //     filterBox.splice(index, 1);
    //   }

    // }

    console.log(filterBox);
  }
  function setCheckTime(e, code, status,index) {
    console.log(e);
    console.log(code);
    setBoxIndexTwo(index)
    filterBox2 = code;
    functionfinalFIlter()
  }

  function functionfinalFIlter() {
    let userId = localStorage.getItem("userId");
    const authToken = localStorage.getItem("token");
    let status = filterBox
    let times = filterBox2
    let apiUrl = ''
    if (!userId || !authToken) {
      // Handle the case where "userId" or "token" is not available in local storage
      console.error("User ID or token is missing in local storage");
      return;
    }
    if (status || !times) {
      apiUrl = `https://aanvikshikiartapi.aanvikshiki.art/api/Payment/getOrder?status=${status}`;
    }
    else if (!status || times) {
      apiUrl = `https://aanvikshikiartapi.aanvikshiki.art/api/Payment/getOrder?pastTime=${times}`;
    }
    else {
      apiUrl = `https://aanvikshikiartapi.aanvikshiki.art/api/Payment/getOrder?status=${status}&pastTime=${times}`;
    }

    const config = {
      headers: {
        Authorization: authToken,
      },
    };

    return Axios.get(apiUrl, config)
      .then((res) => {
        console.log(res.data); // Assuming the response data is what you want to log
        setAlltherData([])
        setAlltherData(res.data)
      })
      .catch((err) => {
        console.error("Error contacting the server: ", err);
        throw err; // Rethrow the error for further handling in the calling code
      });
  }

  return (
    <>
      <div className="orderpage-content">
        <div className="container">
          <div className="anviski-MultiStep-form">
            <h2 className="my-order">My Orders</h2>
            <div className="row">
              <div className="col-md-3">
                <div className="myOrder-contentBelow">
                  <div className="orederBelow-Box">
                    <div className="top-filter-section d-flex justify-content-between">
                      <h4 className="filter-edit">Filter orders</h4>
                      {/* <div className="clear-btn">
                        <button className="clear-all">Clear All</button>
                      </div> */}
                    </div>
                    <form className="order-filter">
                      <label
                        htmlFor="order-status"
                        className="col-form-label order-status"
                      >
                        Order Status
                      </label>
                      {/* {allFilters.map((value) => (
                        <div key={value.id} className="form-check filter-formCheck">
                          <input
                            className="form-check-input formInput-boxes"
                            type="radio"
                            defaultValue=""
                            onChange={(e) => setCheckstatus(e.target.checked, value.code, value.type)}
                            id={`flexCheckDeliver-${value.id}`}

                          />
                          <label
                            className="form-check-label formlevels-name"
                            htmlFor={`flexCheckDeliver-${value.id}`}

                          >
                            {value.title}
                          </label>
                        </div>
                      ))} */}

                      {allFilters.map((value, index) => (
                        <div key={value.id} className="form-check filter-formCheck">
                          <input
                            className="form-check-input formInput-boxes"
                            type="radio"
                            name="filterGroup" // Add a common name for the radio group
                            value={value.code}
                            onChange={(e) => setCheckstatus(e.target.checked, value.code, value.type, index)}
                            id={`flexCheckDeliver-${value.id}`}
                            checked={index === boxIndex}
                          />
                          <label
                            className="form-check-label formlevels-name"
                            htmlFor={`flexCheckDeliver-${value.id}`}
                          >
                            {value.title}
                          </label>
                        </div>
                      ))}


                      <label
                        htmlFor="order-status"
                        className="col-form-label order-status"
                      >
                        Order Time
                      </label>
                      {dateFilters.map((value , index) => (
                        <div key={value.id} className="form-check filter-formCheck">
                          <input
                            className="form-check-input formInput-boxes"
                            type="radio"
                            name="dateFilterGroup" // Add the name attribute
                            defaultValue=""
                            onChange={(e) => setCheckTime(e.target.checked, value.code, value.type,index)}
                            id={`flexCheckDeliver-${value.id}`}
                            checked={index === boxIndexTwo}
                          />
                          <label
                            className="form-check-label formlevels-name"
                            htmlFor={`flexCheckDeliver-${value.id}`}
                          >
                            {value.title}
                          </label>
                        </div>
                      ))}

                    </form>
                    {/* <ul className="filter-apply">
                      <li><button className="clear-filter">Clear Filters</button></li>
                      <li><button className="apply-filter">Apply</button></li>
                    </ul> */}
                  </div>
                </div>
              </div>
              <div className="col-md-7 mr-auto">
                {altheOrders.map((element, index) => (

                  <div key={index} className="orderstatus-content">
                    <div className="media orderstatus-main">
                      <div className="orderstatus-leftimg align-self-center mr-3">
                        <img className="status-image" src="./images/order/cross.svg" alt="no-data" />
                      </div>
                      <div className="media-body toptitle-data">
                        <h5 className="title-header mt-0">{element?.orderStatus}</h5>
                        <p className="order-date">
                          on {formatDate(element?.createdDate)} as per your request
                        </p>
                        <div>
                          {element?.orderStatus === 'cancelled' ? (
                            <p className="order-status-title mb-0">
                              <span className="orderstatus-status">
                                Refund Initiated:
                              </span>
                              ₹{element?.framePrice} on Tue, 17 March 2023.
                            </p>
                          ) : null}
                        </div>
                      </div>
                    </div>
                    <div className="media orderdetails-main">

                      <div className="orderdetails-leftimg align-self-center mr-3">
                        <img src={element?.frames?.base64Images[0]} className="details-image" alt="no-data" />
                      </div>
                      <div className="media-body toptitle-data">
                        <h5 className="title-header mt-0">{element.frames?.title}</h5>
                        <p className="order-itemname">{element.frames?.categories}</p>
                        {/* <p className="order-details-quantity mb-0">
                          <span className="orderquantity-status">Qty:</span> 1
                        </p> */}
                        <p className="order-details-quantity mb-0">
                          <span className="orderquantity-status">Size:</span> {element.craftSizes.size} In
                        </p>
                        {/* <p>{element.frames.base64Images[0]}</p> */}
                      </div>
                    </div>
                    <ul className="payment-methods">
                      <li className="paymenttitle">
                        <span className="insidepayments-span">
                          Payment method:
                        </span>{" "}
                        
                        {element?.paymentDetails?.method}
                      </li>
                      <li className="orderid-data">
                        <span className="orderid-span">Order ID #</span> {element?.orderDelivery?.orderId}
                      </li>
                    </ul>
                    <h3 className="account-details">{element?.paymentDetails?.card_id}</h3>
                    <h3 className="delivery-address">Delivery Address</h3>
                    <p className="address-data">
                      {element.CardDeliveryAddress.name},
                      {element.CardDeliveryAddress.address},
                      {element.CardDeliveryAddress.area},
                      {element.CardDeliveryAddress.city} -  {element.CardDeliveryAddress.pincode}
                      <p>Mobile No : {element.CardDeliveryAddress.mobile}</p>
                    </p>
                  </div>
                ))}
              </div>

            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Orderpage;
