import React, { useEffect, useRef, useState } from "react";
import { NavLink } from "react-router-dom";
import Axios from "axios";
import { useParams } from "react-router-dom";
const Ai = () => {
  const { id } = useParams();
  const sectionRef = useRef(null);
  const [allcategoryList, setAllcategorylist] = useState([]);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [imageScroll, setImagesSroll] = useState([]);
  const [framecount, setFramecount] = useState([]);
  const [getFliters, setFilters] = useState([]);
  const [getCategoryName, setCategoryName] = useState("");
  const [getCategory, setCategory] = useState("");

  const [cateGoryName, setCateGoryName] = useState('');
  useEffect(() => {
    getFeatureList()
  }, [])
  useEffect(() => {
    window.scroll({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  });
  const showImage = (index) => {

    setCurrentIndex(index);
  };

  const previousImage = () => {
    setCurrentIndex(
      (prevIndex) => (prevIndex - 1 + imageScroll.length) % imageScroll.length
    );
  };

  const nextImage = () => {
    setCurrentIndex((prevIndex) => (prevIndex + 1) % imageScroll.length);
  };

  useEffect(()=>{
    getFeatureList()
  },[id])
  // add to bag post api
  async function getFeatureList () {
    const authToken = localStorage.getItem("token");
    const apiUrl =
      `https://aanvikshikiartapi.aanvikshiki.art/api/frame/getFrameByTitle?category=${id}`;

    // Retrieve the token from local storage

    Axios.get(apiUrl)
      .then((res) => {

        setFilters(res.data)
      })
      .catch((err) => {
        console.error("Error adding address:", err);
        // Handle the error, display an error message, or perform other error-handling actions.
      });
  };

  function getImageType(image) {
    // Check if the image URL ends with ".mp4"
    return image.toLowerCase().endsWith(".mp4");
  }

  var cateId = ''
  var selected = id

  useEffect(() => {
    // let selected = localStorage.getItem("routeChange");
    if (!selected) {
      selected = id
    }
    let data = {
      categories: selected,
    };
    filterData(data);
  }, [selected]);
  function filterData(data) {
    const body = {
      filterId: data.categories,
    };
    setCategoryName(data.categories)
    const apiUrl =
      "https://aanvikshikiartapi.aanvikshiki.art/api/frame/filterById";
    Axios.post(apiUrl, body)
      .then((res) => {

        cateId = res?.data[0]?.categories
        setCategory(cateId)
       
        setCateGoryName(res?.data[0].categories)
        setAllcategorylist(res?.data);
        setFramecount(res?.data.length);
        res.data.forEach((obj) => {
          obj.imageType = getImageType(obj.image);
        });
        setAllcategorylist(res?.data);
      })
      .catch((err) => {
        console.error("Error contact us ", err);
      });
  }


  function filterName(category, name) {
    const body = {
      categories: category,
      name: name,
    };

    const apiUrl =
      "https://aanvikshikiartapi.aanvikshiki.art/api/frame/filterByName";
    Axios.post(apiUrl, body)
      .then((res) => {
        setCategoryName(res.data[0].categories)

        setAllcategorylist([]);
        setAllcategorylist(res?.data);
        setFramecount(res?.data.length);
      })
      .catch((err) => {
        console.error("Error contact us ", err);
      });
  }

  function handleCheckboxChange(e, event) {
    if (event.target.checked) {
      filterName(cateGoryName, e)
    }
    else {

    }

  }
  return (
    <>
      <div className="pigmentprinting-content">
        <div className="pigmentprinting-main">
          <div className="containerrr">
            <div className="row">
              <div className="col-md-12">
              <h3 className="page-title">
                <NavLink className="page-titlespan" to="/landing">
                  {" "}
                  Home /{" "}
                </NavLink>{" "}
                <NavLink className="page-titlespan" to="/landing">
                  {" "}
                  {getCategoryName} {" "}
                </NavLink>
              </h3>
              </div>
              <div className="col-md-3">
                <div className="left-filter-printing">
                  <h1 className="left-head">Collections</h1>
                  {getFliters.map((item, index) => (
                    <div key={index} className="form-check">
                      <input
                        className="form-check-input radio-check-cusr"
                        type="radio"
                        name="filterGroup" // Add a common name for the radio group
                        value={item} // Set the value to the item (or its unique identifier)
                        onChange={(event) => handleCheckboxChange(item, event)}
                        id={`flexRadioChecked_${index}`}
                      />
                      <label className="form-check-label radio-label" htmlFor={`flexRadioChecked_${index}`}>
                        {item} {/* Assuming your data has a label property */}
                      </label>
                    </div>
                  ))}

                </div>

                {/* mobile filter */}
                <div className="filter-names-mob">
                  <>
                    <div className="filter-data">
                      <a
                        className="btn filter-btn"
                        data-bs-toggle="offcanvas"
                        href="#offcanvasExample"
                        role="button"
                        aria-controls="offcanvasExample"
                      >
                        <i class="fa-solid fa-filter"></i> <span className="filter-name">Filter</span>
                      </a>
                    </div>
                    <div
                      className="offcanvas offcanvas-start"
                      tabIndex={-1}
                      id="offcanvasExample"
                      aria-labelledby="offcanvasExampleLabel"
                    >
                      <div className="offcanvas-header">
                        <h5 className="offcanvas-title" id="offcanvasExampleLabel">
                          Collections
                        </h5>
                        <button
                          type="button"
                          className="btn-close text-reset"
                          data-bs-dismiss="offcanvas"
                          aria-label="Close"
                        />
                      </div>
                      <div className="left-filter-printing1">
                        {/* <h1 className="left-head">Collections</h1> */}
                        {getFliters.map((item, index) => (
                          <div key={index} className="form-check">
                            <input
                              className="form-check-input radio-check-cusr"
                              type="radio"
                              name="filterGroup" // Add a common name for the radio group
                              value={item} // Set the value to the item (or its unique identifier)
                              onChange={(event) => handleCheckboxChange(item, event)}
                              id={`flexRadioChecked_${index}`}
                            />
                            <label className="form-check-label radio-label" htmlFor={`flexRadioChecked_${index}`}>
                              {item} {/* Assuming your data has a label property */}
                            </label>
                          </div>
                        ))}

                      </div>
                    </div>
                  </>
                </div>
              </div>
              <div className="col-md-9">
                {/* <ul className="filter-top">
              <li>Filter</li>
              <li className="total-products">{framecount} products</li>
              <li>Relevance</li>
            </ul> */}
                <div className="pigmentlists-data">
                  {/* <div className="row"> */}
                  {allcategoryList.length === 0 ? (
                    <p className="no-items">No items are available</p>
                  ) : (
                    <ul className="card-main-content">
                      {allcategoryList.map((frames, index) => (
                        <li className="card-box" key={index}>
                          <div className="codemain">
                            <p className="code-list">{frames.code}</p>
                          </div>
                          <NavLink
                            className="card-contents-inside"
                            to={`/card-inner/${frames._id}`}
                          >
                            <div className="card card-inside thumbnails">

                              {/* <img
                                className="card-images"
                                
                                src={`data:image/jpeg;base64,${frames.image}`}
                                alt="no-data"
                              /> */}
                              {frames.imageType ? (
                                <video onClick={() => showImage(index)} className="thumbnail"  >
                                  <source src={frames.image} type="video/mp4" />
                                  Your browser does not support the video tag.
                                </video>
                              ) : (
                                <img src={frames.image} onClick={() => showImage(index)} className="thumbnail" alt='' />
                              )}
                            </div>
                            <div className="card-body card-body-inside">
                              <p className="card-title insidecard-balajititle">
                                {frames.title}
                              </p>
                              {/* <p className="card-text insidecard-balajipara">
              {frames.description}
            </p> */}
                              {getCategory !== 'TANJORE PAINTINGS' ? (
                                null
                                // <h5 className="price-card">Rs. {frames.price}</h5>
                              ) : (
                                null
                              )}

                            </div>
                          </NavLink>
                        </li>
                      ))}
                    </ul>
                  )}

                  {/* </div> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Ai;
