import React, {useEffect, useRef } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';

import "react-loading-skeleton/dist/skeleton.css";
import Axios from "axios";
import toastr from "toastr";
import 'toastr/build/toastr.css';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css'; // Import the styles
// Import Swiper React components
// import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";

import { NavLink } from 'react-router-dom';
// Import Swiper styles
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';
 
import { Autoplay } from "swiper/modules";
// import required modules
import { Pagination, Navigation } from 'swiper/modules';

const SrinivasaKalyanam = () => {
    const swiperRef1 = useRef(null);
    const swiperRef2 = useRef(null);

    const handleNext1 = () => {
        if (swiperRef1.current && swiperRef1.current.swiper) {
            swiperRef1.current.swiper.slideNext();
        }
    };

    const handlePrev1 = () => {
        if (swiperRef1.current && swiperRef1.current.swiper) {
            swiperRef1.current.swiper.slidePrev();
        }
    };

    const handleNext2 = () => {
        if (swiperRef2.current && swiperRef2.current.swiper) {
            swiperRef2.current.swiper.slideNext();
        }
    };

    const handlePrev2 = () => {
        if (swiperRef2.current && swiperRef2.current.swiper) {
            swiperRef2.current.swiper.slidePrev();
        }
    };
    useEffect(() => {
        window.scroll({
          top: 0,
          left: 0,
          behavior: "smooth",
        });
      }, []);

    return (
        <>
            <div className='sriniva-details'>
                <h1 className='divine-head'>The Divine Matrimony of Srinivasa Kalyanam:</h1>
                <h1 className='divine-head1'>A Team Aanvikshiki Masterpiece</h1>
                <p className='embrak-para'>Embark on a timeless voyage through the "Srinivasa Kalyanam," a celestial tale artfully depicted in the divine Aanvikshiki Tanjore painting. This sacred artwork is not merely a visual delight; it's the unfolding of an epic saga, capturing the quintessential marriage that shaped the heavens.</p>

                <div className="detail-view">

                <div className='container'>
                <Swiper

                ref={swiperRef1}
                navigation={{
                    prevEl: '#customPrevButton1',
                    nextEl: '#customNextButton1'
                }}
                className="mySwiper swiper-section-top"
                pagination={false}
                loop={true}
                spaceBetween={30}
                // slidesPerView={2}
                centeredSlides={false} // Center the active slide
                autoplay={{
                  delay: 2500,
                  disableOnInteraction: false,
                  pauseOnMouseEnter: true,
                  waitForTransition: true,
                }}
                speed={1000}
                breakpoints={{
                  640: {
                    slidesPerView: 1,
                  },
                  768: {
                    slidesPerView: 1,
                  },
                  1024: {
                    slidesPerView: 2,

                  },
                }}
                modules={[Pagination, Navigation, Autoplay]}
                >
                    <SwiperSlide>
          <div className="card card-box">
              <div className="card-main">
                  <img
                      src="./images/landing/srinivasa/1301178564.jpg"
                      className="card-img-top recom-img"
                      alt="..."
                  />
              </div>
          </div>
      </SwiperSlide>
      <SwiperSlide>
          <div className="card card-box">
              <div className="card-main">
                  <img
                      src="./images/landing/srinivasa/1301178564-2.jpg"
                      className="card-img-top recom-img"
                      alt="..."
                  />
              </div>
          </div>
      </SwiperSlide>
      <SwiperSlide>
          <div className="card card-box">
              <div className="card-main">
                  <img
                      src="./images/landing/srinivasa/1301178564-3.jpg"
                      className="card-img-top recom-img"
                      alt="..."
                  />
              </div>
          </div>
      </SwiperSlide>
      <SwiperSlide>
          <div className="card card-box">
              <div className="card-main">
                  <img
                      src="./images/landing/srinivasa/1301178564-4.jpg"
                      className="card-img-top recom-img"
                      alt="..."
                  />
              </div>
          </div>
      </SwiperSlide>
      <SwiperSlide>
          <div className="card card-box">
              <div className="card-main">
                  <img
                      src="./images/landing/srinivasa/1301178564-5.jpg"
                      className="card-img-top recom-img"
                      alt="..."
                  />
              </div>
          </div>
      </SwiperSlide>
      <SwiperSlide>
          <div className="card card-box">
              <div className="card-main">
                  <img
                      src="./images/landing/srinivasa/1301178564-6.jpg"
                      className="card-img-top recom-img"
                      alt="..."
                  />
              </div>
          </div>
      </SwiperSlide>
      <SwiperSlide>
          <div className="card card-box">
              <div className="card-main">
                  <img
                      src="./images/landing/srinivasa/1301178564-7.jpg"
                      className="card-img-top recom-img"
                      alt="..."
                  />
              </div>
          </div>
      </SwiperSlide>
      <SwiperSlide>
          <div className="card card-box">
              <div className="card-main">
                  <img
                      src="./images/landing/srinivasa/1301178564-8.jpg"
                      className="card-img-top recom-img"
                      alt="..."
                  />
              </div>
          </div>
      </SwiperSlide>
      <SwiperSlide>
          <div className="card card-box">
              <div className="card-main">
                  <img
                      src="./images/landing/srinivasa/1301178564-9.jpg"
                      className="card-img-top recom-img"
                      alt="..."
                  />
              </div>
          </div>
      </SwiperSlide>
      <SwiperSlide>
          <div className="card card-box">
              <div className="card-main">
                  <img
                      src="./images/landing/srinivasa/1301178564-10.jpg"
                      className="card-img-top recom-img"
                      alt="..."
                  />
              </div>
          </div>
      </SwiperSlide>
      <SwiperSlide>
          <div className="card card-box">
              <div className="card-main">
                  <img
                      src="./images/landing/srinivasa/1301178564-11.jpg"
                      className="card-img-top recom-img"
                      alt="..."
                  />
              </div>
          </div>
      </SwiperSlide>
      <SwiperSlide>
          <div className="card card-box">
              <div className="card-main">
                  <img
                      src="./images/landing/srinivasa/1301178564-12.jpg"
                      className="card-img-top recom-img"
                      alt="..."
                  />
              </div>
          </div>
      </SwiperSlide>
      <SwiperSlide>
          <div className="card card-box">
              <div className="card-main">
                  <img
                      src="./images/landing/srinivasa/1301178564-13.jpg"
                      className="card-img-top recom-img"
                      alt="..."
                  />
              </div>
          </div>
      </SwiperSlide>
      <SwiperSlide>
          <div className="card card-box">
              <div className="card-main">
                  <img
                      src="./images/landing/srinivasa/1301178564-14.jpg"
                      className="card-img-top recom-img"
                      alt="..."
                  />
              </div>
          </div>
      </SwiperSlide>
      <SwiperSlide>
          <div className="card card-box">
              <div className="card-main">
                  <img
                      src="./images/landing/srinivasa/1301178564-15.jpg"
                      className="card-img-top recom-img"
                      alt="..."
                  />
              </div>
          </div>
      </SwiperSlide>
      <SwiperSlide>
          <div className="card card-box">
              <div className="card-main">
                  <img
                      src="./images/landing/srinivasa/1301178564-16.jpg"
                      className="card-img-top recom-img"
                      alt="..."
                  />
              </div>
          </div>
      </SwiperSlide>
      <SwiperSlide>
          <div className="card card-box">
              <div className="card-main">
                  <img
                      src="./images/landing/srinivasa/1301178564-17.jpg"
                      className="card-img-top recom-img"
                      alt="..."
                  />
              </div>
          </div>
      </SwiperSlide>
      <SwiperSlide>
          <div className="card card-box">
              <div className="card-main">
                  <img
                      src="./images/landing/srinivasa/1301178564-18.jpg"
                      className="card-img-top recom-img"
                      alt="..."
                  />
              </div>
          </div>
      </SwiperSlide>
      <SwiperSlide>
          <div className="card card-box">
              <div className="card-main">
                  <img
                      src="./images/landing/srinivasa/1301178564-19.jpg"
                      className="card-img-top recom-img"
                      alt="..."
                  />
              </div>
          </div>
      </SwiperSlide>
      <SwiperSlide>
          <div className="card card-box">
              <div className="card-main">
                  <img
                      src="./images/landing/srinivasa/1301178564-20.jpg"
                      className="card-img-top recom-img"
                      alt="..."
                  />
              </div>
          </div>
      </SwiperSlide>
      <SwiperSlide>
          <div className="card card-box">
              <div className="card-main">
                  <img
                      src="./images/landing/srinivasa/1301178564-21.jpg"
                      className="card-img-top recom-img"
                      alt="..."
                  />
              </div>
          </div>
      </SwiperSlide>
      <SwiperSlide>
          <div className="card card-box">
              <div className="card-main">
                  <img
                      src="./images/landing/srinivasa/1301178564-22.jpg"
                      className="card-img-top recom-img"
                      alt="..."
                  />
              </div>
          </div>
      </SwiperSlide>
      <SwiperSlide>
          <div className="card card-box">
              <div className="card-main">
                  <img
                      src="./images/landing/srinivasa/1301178564-23.jpg"
                      className="card-img-top recom-img"
                      alt="..."
                  />
              </div>
          </div>
      </SwiperSlide>
      <SwiperSlide>
          <div className="card card-box">
              <div className="card-main">
                  <img
                      src="./images/landing/srinivasa/1301178564-24.jpg"
                      className="card-img-top recom-img"
                      alt="..."
                  />
              </div>
          </div>
      </SwiperSlide>
      <SwiperSlide>
          <div className="card card-box">
              <div className="card-main">
                  <img
                      src="./images/landing/srinivasa/1301178564-25.jpg"
                      className="card-img-top recom-img"
                      alt="..."
                  />
              </div>
          </div>
      </SwiperSlide>
      <SwiperSlide>
          <div className="card card-box">
              <div className="card-main">
                  <img
                      src="./images/landing/srinivasa/1301178564-26.jpg"
                      className="card-img-top recom-img"
                      alt="..."
                  />
              </div>
          </div>
      </SwiperSlide>
      <SwiperSlide>
          <div className="card card-box">
              <div className="card-main">
                  <img
                      src="./images/landing/srinivasa/1301178564-27.jpg"
                      className="card-img-top recom-img"
                      alt="..."
                  />
              </div>
          </div>
      </SwiperSlide>
      <SwiperSlide>
          <div className="card card-box">
              <div className="card-main">
                  <img
                      src="./images/landing/srinivasa/1301178564-28.jpg"
                      className="card-img-top recom-img"
                      alt="..."
                  />
              </div>
          </div>
      </SwiperSlide>
      <SwiperSlide>
          <div className="card card-box">
              <div className="card-main">
                  <img
                      src="./images/landing/srinivasa/1301178564-29.jpg"
                      className="card-img-top recom-img"
                      alt="..."
                  />
              </div>
          </div>
      </SwiperSlide>
      <SwiperSlide>
          <div className="card card-box">
              <div className="card-main">
                  <img
                      src="./images/landing/srinivasa/1301178564-30.jpg"
                      className="card-img-top recom-img"
                      alt="..."
                  />
              </div>
          </div>
      </SwiperSlide>
      <SwiperSlide>
          <div className="card card-box">
              <div className="card-main">
                  <img
                      src="./images/landing/srinivasa/1301178564-31.jpg"
                      className="card-img-top recom-img"
                      alt="..."
                  />
              </div>
          </div>
      </SwiperSlide>
      <SwiperSlide>
          <div className="card card-box">
              <div className="card-main">
                  <img
                      src="./images/landing/srinivasa/1301178564-32.jpg"
                      className="card-img-top recom-img"
                      alt="..."
                  />
              </div>
          </div>
      </SwiperSlide>
      <SwiperSlide>
          <div className="card card-box">
              <div className="card-main">
                  <img
                      src="./images/landing/srinivasa/1301178564-33.jpg"
                      className="card-img-top recom-img"
                      alt="..."
                  />
              </div>
          </div>
      </SwiperSlide>
      <SwiperSlide>
          <div className="card card-box">
              <div className="card-main">
                  <img
                      src="./images/landing/srinivasa/1301178564-34.jpg"
                      className="card-img-top recom-img"
                      alt="..."
                  />
              </div>
          </div>
      </SwiperSlide>
      <SwiperSlide>
          <div className="card card-box">
              <div className="card-main">
                  <img
                      src="./images/landing/srinivasa/1301178564-35.jpg"
                      className="card-img-top recom-img"
                      alt="..."
                  />
              </div>
          </div>
      </SwiperSlide>
      <SwiperSlide>
          <div className="card card-box">
              <div className="card-main">
                  <img
                      src="./images/landing/srinivasa/1301178564-36.jpg"
                      className="card-img-top recom-img"
                      alt="..."
                  />
              </div>
          </div>
      </SwiperSlide>
      <SwiperSlide>
          <div className="card card-box">
              <div className="card-main">
                  <img
                      src="./images/landing/srinivasa/1301178564-37.jpg"
                      className="card-img-top recom-img"
                      alt="..."
                  />
              </div>
          </div>
      </SwiperSlide>
      <SwiperSlide>
          <div className="card card-box">
              <div className="card-main">
                  <img
                      src="./images/landing/srinivasa/1301178564-38.jpg"
                      className="card-img-top recom-img"
                      alt="..."
                  />
              </div>
          </div>
      </SwiperSlide>
      <SwiperSlide>
          <div className="card card-box">
              <div className="card-main">
                  <img
                      src="./images/landing/srinivasa/1301178564-39.jpg"
                      className="card-img-top recom-img"
                      alt="..."
                  />
              </div>
          </div>
      </SwiperSlide>
      <SwiperSlide>
          <div className="card card-box">
              <div className="card-main">
                  <img
                      src="./images/landing/srinivasa/1301178564-40.jpg"
                      className="card-img-top recom-img"
                      alt="..."
                  />
              </div>
          </div>
      </SwiperSlide>
      <SwiperSlide>
          <div className="card card-box">
              <div className="card-main">
                  <img
                      src="./images/landing/srinivasa/1301178564-41.jpg"
                      className="card-img-top recom-img"
                      alt="..."
                  />
              </div>
          </div>
      </SwiperSlide>
      <SwiperSlide>
          <div className="card card-box">
              <div className="card-main">
                  <img
                      src="./images/landing/srinivasa/1301178564-42.jpg"
                      className="card-img-top recom-img"
                      alt="..."
                  />
              </div>
          </div>
      </SwiperSlide>
      <SwiperSlide>
          <div className="card card-box">
              <div className="card-main">
                  <img
                      src="./images/landing/srinivasa/1301178564-43.jpg"
                      className="card-img-top recom-img"
                      alt="..."
                  />
              </div>
          </div>
      </SwiperSlide>
      <SwiperSlide>
          <div className="card card-box">
              <div className="card-main">
                  <img
                      src="./images/landing/srinivasa/1301178564-44.jpg"
                      className="card-img-top recom-img"
                      alt="..."
                  />
              </div>
          </div>
      </SwiperSlide>
      <SwiperSlide>
          <div className="card card-box">
              <div className="card-main">
                  <img
                      src="./images/landing/srinivasa/1301178564-45.jpg"
                      className="card-img-top recom-img"
                      alt="..."
                  />
              </div>
          </div>
      </SwiperSlide>
      <SwiperSlide>
          <div className="card card-box">
              <div className="card-main">
                  <img
                      src="./images/landing/srinivasa/1301178564-46.jpg"
                      className="card-img-top recom-img"
                      alt="..."
                  />
              </div>
          </div>
      </SwiperSlide>
      <SwiperSlide>
          <div className="card card-box">
              <div className="card-main">
                  <img
                      src="./images/landing/srinivasa/1301178564-47.jpg"
                      className="card-img-top recom-img"
                      alt="..."
                  />
              </div>
          </div>
      </SwiperSlide>
      <SwiperSlide>
          <div className="card card-box">
              <div className="card-main">
                  <img
                      src="./images/landing/srinivasa/1301178564-48.jpg"
                      className="card-img-top recom-img"
                      alt="..."
                  />
              </div>
          </div>
      </SwiperSlide>
      <SwiperSlide>
          <div className="card card-box">
              <div className="card-main">
                  <img
                      src="./images/landing/srinivasa/1301178564-49.jpg"
                      className="card-img-top recom-img"
                      alt="..."
                  />
              </div>
          </div>
      </SwiperSlide>
      <SwiperSlide>
          <div className="card card-box">
              <div className="card-main">
                  <img
                      src="./images/landing/srinivasa/1301178564-50.jpg"
                      className="card-img-top recom-img"
                      alt="..."
                  />
              </div>
          </div>
      </SwiperSlide>
      <SwiperSlide>
          <div className="card card-box">
              <div className="card-main">
                  <img
                      src="./images/landing/srinivasa/1301178564-51.jpg"
                      className="card-img-top recom-img"
                      alt="..."
                  />
              </div>
          </div>
      </SwiperSlide>
      <SwiperSlide>
          <div className="card card-box">
              <div className="card-main">
                  <img
                      src="./images/landing/srinivasa/1301178564-52.jpg"
                      className="card-img-top recom-img"
                      alt="..."
                  />
              </div>
          </div>
      </SwiperSlide>
 
                </Swiper>
                <div className="btns-below">
                    <button id="customPrevButton1" className="next-btn" onClick={handlePrev1}>
                    <i class="fa-solid fa-arrow-left arrow-l"></i>Previous
                    </button>
                    <button id="customNextButton1" className="next-btn" onClick={handleNext1}>
                        Next<i class="fa-solid fa-arrow-right arrow-r"></i>
                    </button>
                </div>
            </div>


              
                </div>

                <div className='srinivasa-video'>
                    <div className='container-fluid'>
                        <h1 className='story-head'>Unveiling the Divine Narrative: The Story of Srinivasa Kalyanam by Aanvikshiki Tanjore Paintings</h1>
                       
                        <div className='videos'>
                        <iframe className='video-load' src="https://www.youtube.com/embed/O-SSdnqQ68M" title="Aanvikshiki Presents: Vakulamatha Unveils the Tale of Srinivasa Kalyanam | Hindi" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
                                </div>
                        {/* <ul class="nav nav-pills mb-3 video-data" id="pills-tab" role="tablist">
                            <li class="nav-item" role="presentation">
                                <button class="nav-link tab-video active" id="pills-home-tab" data-bs-toggle="pill" data-bs-target="#pills-home" type="button" role="tab" aria-controls="pills-home" aria-selected="true">Telugu</button>
                            </li>
                            <li class="nav-item" role="presentation">
                                <button class="nav-link tab-video" id="pills-profile-tab" data-bs-toggle="pill" data-bs-target="#pills-profile" type="button" role="tab" aria-controls="pills-profile" aria-selected="false">kannada</button>
                            </li>
                            <li class="nav-item" role="presentation">
                                <button class="nav-link tab-video" id="pills-contact-tab" data-bs-toggle="pill" data-bs-target="#pills-contact" type="button" role="tab" aria-controls="pills-contact" aria-selected="false">Hindi</button>
                            </li>
                        </ul> */}
                        {/* <div class="tab-content" id="pills-tabContent">
                            <div class="tab-pane fade show active" id="pills-home" role="tabpanel" aria-labelledby="pills-home-tab">
                                <div className='videos'>
                                    <iframe className='video-load' src="https://www.youtube.com/embed/9aCC9FOAl1Q" title="Vakulamatha Unveils the Tale of Srinivasa Kalyanam: A Srinivasa Kalyanam Special - Telugu" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
                                </div>
                            </div>
                            <div class="tab-pane fade" id="pills-profile" role="tabpanel" aria-labelledby="pills-profile-tab">
                                <div className='videos'>
                                    <iframe className='video-load' src="https://www.youtube.com/embed/qGJv9sLgMEc" title="Vakulamatha Unveils the Tale of Srinivasa Kalyanam: A Srinivasa Kalyanam Special - kannada" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>

                                </div>
                            </div>
                            <div class="tab-pane fade" id="pills-contact" role="tabpanel" aria-labelledby="pills-contact-tab">
                                <div className='videos'>
                                    <iframe className='video-load' src="https://www.youtube.com/embed/wODJwrWa_2s" title="Vakulamatha Unveils the Tale of Srinivasa Kalyanam: A Srinivasa Kalyanam Special - Hindi" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
                                </div>
                            </div>
                        </div> */}


                        {/* <ul class="nav nav-pills mb-3 video-data" id="pills-tab" role="tablist">
                            <li class="nav-item" role="presentation">
                                <button class="nav-link tab-video active" id="pills-home-tab" data-bs-toggle="pill" data-bs-target="#pills-home" type="button" role="tab" aria-controls="pills-home" aria-selected="true">TELUGU</button>
                            </li>
                            <li class="nav-item" role="presentation">
                                <button class="nav-link tab-video" id="pills-profile-tab" data-bs-toggle="pill" data-bs-target="#pills-profile" type="button" role="tab" aria-controls="pills-profile" aria-selected="false">KANNADA</button>
                            </li>
                            <li class="nav-item" role="presentation">
                                <button class="nav-link tab-video" id="pills-contact-tab" data-bs-toggle="pill" data-bs-target="#pills-contact" type="button" role="tab" aria-controls="pills-contact" aria-selected="false">HINDI</button>
                            </li>

                        </ul>
                        <div class="tab-content" id="pills-tabContent">
                            <div class="tab-pane fade show active" id="pills-home" role="tabpanel" aria-labelledby="pills-home-tab" tabindex="0">
                                <div className='videos'>
                                    <iframe className='video-load' src="https://www.youtube.com/embed/9aCC9FOAl1Q" title="Vakulamatha Unveils the Tale of Srinivasa Kalyanam: A Srinivasa Kalyanam Special - Telugu" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
                                </div>

                            </div>
                            <div class="tab-pane fade" id="pills-profile" role="tabpanel" aria-labelledby="pills-profile-tab" tabindex="0">
                                <div className='videos'>
                                    <iframe className='video-load' src="https://www.youtube.com/embed/qGJv9sLgMEc" title="Vakulamatha Unveils the Tale of Srinivasa Kalyanam: A Srinivasa Kalyanam Special - kannada" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>

                                </div>
                            </div>
                            <div class="tab-pane fade" id="pills-contact" role="tabpanel" aria-labelledby="pills-contact-tab" tabindex="0">
                                <div className='videos'>
                                    <iframe className='video-load' src="https://www.youtube.com/embed/wODJwrWa_2s" title="Vakulamatha Unveils the Tale of Srinivasa Kalyanam: A Srinivasa Kalyanam Special - Hindi" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
                                </div>
                            </div>
                        </div> */}

                    </div>
                </div>

                {/* =========================== */}
                <div className='swamy-data'>
                    <div className='container-fluid'>
                        <div className='row'>
                            <div className='col-md-7'>
                                <div className="card mb-3 listdata">
                                    <div className="row g-0">
                                        <div className="col-md-1">
                                            <div className='number'>
                                                <h1 className='numbers-list'>01</h1>
                                            </div>
                                        </div>
                                        <div className="col-md-11">
                                            <div className="card-body data-content">
                                                <h5 className="card-title data-title">The Divine Courtship</h5>
                                                <p className="card-text data-paras">In the mystical realms of Hindu mythology, there unfolds a story of love, devotion, and cosmic designs. Lord Srinivasa, an incarnation of Lord Vishnu, descends upon the Earth, his heart set on the beautiful and virtuous Padmavati. Their meeting is no accident but a play of destiny, written in the stars and witnessed by the gods.</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="card mb-3 listdata">
                                    <div className="row g-0">
                                        <div className="col-md-1">
                                            <div className='number'>
                                                <h1 className='numbers-list'>02</h1>
                                            </div>
                                        </div>
                                        <div className="col-md-11">
                                            <div className="card-body data-content">
                                                <h5 className="card-title data-title">A Marriage Made in Heaven</h5>
                                                <p className="card-text data-paras">As the narrative progresses, so does their divine love. The wedding of Srinivasa and Padmavati is a grand affair that sees the convergence of the celestial and the terrestrial. Deities, sages, and celestial beings gather, marking an event that transcends the mere joining of two souls—it symbolizes the harmonious balance of the universe.</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="card mb-3 listdata">
                                    <div className="row g-0">
                                        <div className="col-md-1">
                                            <div className='number'>
                                                <h1 className='numbers-list'>03</h1>
                                            </div>
                                        </div>
                                        <div className="col-md-11">
                                            <div className="card-body data-content">
                                                <h5 className="card-title data-title">The Artistic Chronicle</h5>
                                                <p className="card-text data-paras">Every brushstroke on this Tanjore painting by Aanvikshiki is a verse in the poetry of their love. The rich pigments and original gold foils used are not just materials but the essence of purity and commitment that adorns this sacred union. The detailed workmanship encapsulates the life story of the deities and the prominent figures that play a critical role in this cosmic event.</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="card mb-3 listdata">
                                    <div className="row g-0">
                                        <div className="col-md-1">
                                            <div className='number'>
                                                <h1 className='numbers-list'>04</h1>
                                            </div>
                                        </div>
                                        <div className="col-md-11">
                                            <div className="card-body data-content">
                                                <h5 className="card-title data-title">The Emblem of Eternity</h5>
                                                <p className="card-text data-paras">"Srinivasa Kalyanam" isn't just a story; it's an experience of cultural profundity. Owning this painting is like preserving a chapter of celestial history within your home. It's an investment into a narrative that has shaped the spiritual landscape of generations, offering blessings of eternal love and balance to all who lay eyes upon it.</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="card mb-3 listdata">
                                    <div className="row g-0">
                                        <div className="col-md-1">
                                            <div className='number'>
                                                <h1 className='numbers-list'>05</h1>
                                            </div>
                                        </div>
                                        <div className="col-md-11">
                                            <div className="card-body data-content">
                                                <h5 className="card-title data-title">Invite Blessings into Your Abode</h5>
                                                <p className="card-text data-paras">Make this Aanvikshiki Tanjore painting the cornerstone of your sacred spaces. As it graces your walls, let it not only be a testament to your taste in fine art but also a beacon of auspiciousness, prosperity, and timeless heritage.</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </div>
                            <div className='col-md-5'>
                                <img className="image-balaji"
                                    src="./images/landing/srinivasa/balaji1.png"
                                    alt="balaji"
                                />
                            </div>
                        </div>


                    </div>
                </div>
                {/* =========================srinivasa life ==================== */}
                <div className='balaji-life'>
                    <div className='container-fluid'>
                        <div className='row'>
                            <div className='col-md-6'>
                                <img className="image-balaji1"
                                    src="./images/landing/srinivasa/balaji-namalu.png"
                                    alt="balaji"
                                />
                            </div>
                            <div className='col-md-6'>
                                <div className='lifeofdata'>
                                    <h1 className='skalyanam'>Srinivasa Kalyanam: A Divine Narrative Brought to Life</h1>
                                    <p className='life-content'><span className='life-b'>Srinivasa Kalyanam</span> unfolds the celestial love story of Lord Srinivasa (Lord Vishnu's avatar) and Goddess Padmavati, depicted in a riot of vibrant hues and adorned with gold, symbolizing their divine union. This Tanjore painting, a gem from the Aanvikshiki collection, is a visual representation of celestial matrimony, bringing the grandeur and sanctity of heavenly vows into the earthly realm.</p>
                                    <p className='life-content'><span className='life-b'>Crafted by Legends, for Legacies:</span> Under <span className='life-b'>Mr. A.R. Muthukumar's</span> expert guidance, artists with over four decades of collective experience have infused their souls into this artwork, capturing the divine assembly and the holy fire of vows in exquisite detail. </p>
                                    <p className='life-content'><span className='life-b'>Materials of Timeless Elegance:</span> The artwork's foundation is a canvas cloth stretched over wood, primed with chalk powder for a perfect painting surface. Adorned with natural Arabic Gum, highgrade natural pigments, 22carat gold leaves, and authentic Jaipur gemstones, each element combines to create a spectacle of divine beauty.</p>
                                    <p className='life-content'><span className='life-b'>Framing the Divine:</span> Each "Srinivasa Kalyanam" piece is framed in traditional Chettinad Teak Wood, protected by unbreakable fiberglass, ensuring that its spiritual and aesthetic value endures through the ages. </p>
                                    <p className='life-content'><span className='life-b'>A Symbol of Auspicious Beginnings:</span> Ideal for sanctifying spaces with beauty and blessings, "Srinivasa Kalyanam" enhances Pooja rooms, entrances, study areas, and more, inviting grace and prosperity into all areas of life.</p>
                                    <p className='life-content'><span className='life-b'>Gifting the Divine:</span> This masterpiece serves as the perfect token of blessings for weddings, housewarmings, and festivities, embodying joy, divinity, and the auspiciousness of new beginnings.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* ================================================ */}
                <div className='embrak-content'>
                    <div className='container-fluid'>
                        <h1 className='embrak-head'>Embark on a Journey of Discovery and Connection</h1>
                        <p className='wisdom-para'>"Eternal Wisdom: The Aanvikshiki Masterpiece" and "Srinivasa Kalyanam" together invite you into a realm of artistry, culture, and spiritual depth. These pieces not only represent the pinnacle of traditional art but also serve as beacons of wisdom, love, and divine blessings. Through meticulous craftsmanship, rare materials, and the unique vision of their creators, they stand as unparalleled investments in beauty and heritage.</p>
                        <p className='wisdom-para'>Unique Essence: The fusion of handcrafted tradition, the employment of rare materials, and the singular vision of its creators makes owning a piece from the Aanvikshiki collection a unique privilege. These artworks are not just possessions but legacies of culture and wisdom to be cherished and displayed with pride.</p>
                    </div>
                </div>
                {/* =============================================================== */}
                <div className='balajikalyanam'>
                    <div className='container-fluid'>
                        <div className='kalyanam'>
                            <h1 className='explore-kalyanam'>Explore the profound beauty and significance of "Eternal Wisdom: The Aanvikshiki Masterpiece" and "Srinivasa Kalyanam: A Celestial Confluence in Tanjore Art," and let these masterpieces elevate your space and spirit.</h1>
                            <div className='srinivasa-img'>
                                <img className="sk-image"
                                    src="./images/landing/srinivasa/skk.png"
                                    alt="balaji"
                                />
                                <p className='welcome-head'>Welcome to a world of divine artistry with "Srinivasa Kalyanam: A Celestial Confluence in Tanjore Art by Aanvikshiki," a stunning portrayal of the sacred nuptials between Lord Srinivasa and Goddess Padmavati. This Tanjore painting, rich in tradition and spiritual significance, captures the essence of a celestial event that is as enduring as it is magnificent.</p>
                            </div>
                        </div>
                    </div>
                </div>
                {/* ================================== */}
                <div className='divine-tale'>
                    <div className='container-fluid'>
                        <h1 className='vibrant-head'>The Divine Tale Rendered in Vibrant Hues</h1>
                        <p className='vibrant-para'>The tableau vibrantly comes alive with Lord Srinivasa, an incarnation of Lord Vishnu, standing with regal poise beside his consort, Goddess Padmavati, believed to be an incarnation of Goddess Lakshmi. Together, they personify the ultimate union of divinity and grace. This scene of divine matrimony is celebrated by a celestial assemblage, each deity and figure meticulously depicted to play their part in this cosmic event:</p>
                        <h1 className='grace-head'>The divine couple is also graced by the presence of their earthbound parents:</h1>
                        <p className='vakula'><span className='vakuladevi'>Vakuladevi:</span> The foster mother to Lord Srinivasa, guiding his earthly sojourn.</p>
                        <p className='akasa'><span className='vakuladevi'>Akasa Raju & Dharani Devi:</span> The king and queen who nurture Goddess Padmavati, later giving their blessings to the divine union.</p>
                        <div className='row'>
                            <div className='col-md-6'>
                                <div className='content-list'>
                                    <p className='gods-data'><span className='god-name'>Saraswathi Devi:</span> The embodiment of wisdom, bestowing the boon of knowledge.</p>
                                    <p className='gods-data'><span className='god-name'>Agni Deva:</span> The divine witness to the holy matrimony, symbolizing the eternal fire of commitment.</p>
                                    <p className='gods-data'><span className='god-name'>Vishwaksena:</span> The remover of obstacles, ensuring the ceremony's sanctity.</p>
                                    <p className='gods-data'><span className='god-name'>Parvathi Devi:</span> The goddess of love and devotion, blessing the union with fertility and prosperity.</p>
                                    <p className='gods-data'><span className='god-name'>Hayagreeva:</span> The avatar of wisdom, representing the power of knowledge.</p>
                                    <p className='gods-data'><span className='god-name'>Brahma Deva:</span> The cosmic officiator of the sacred wedding rituals.</p>
                                    <p className='gods-data'><span className='god-name'>Siva (Mahadeva):</span> The supreme being, symbolizing the cycle of creation, preservation, and dissolution.</p>
                                </div>
                            </div>
                            <div className='col-md-6'>
                                <div className='content-list1'>
                                    <p className='gods-data1'><span className='god-name'>Narada Maharshi:</span> The sage navigating the divine and earthly realms, a messenger of the gods.</p>
                                    <p className='gods-data1'><span className='god-name'>Subramanya Swamy:</span> The youthful deity of power and virtue.</p>
                                    <p className='gods-data1'><span className='god-name'>KuberaSwamy:</span> The god of wealth, treasurer of the heavens.</p>
                                    <p className='gods-data1'><span className='god-name'>Ganesha:</span> The auspicious start to all ventures and lord of new beginnings.</p>
                                    <p className='gods-data1'><span className='god-name'>Bruhaspathi:</span> The preceptor of the gods, guiding wisdom and learning.</p>
                                    <p className='gods-data1'><span className='god-name'>Hanuman:</span> The paragon of devotion and strength.</p>
                                    <p className='gods-data1'><span className='god-name'>Garuda:</span> The vahana (vehicle) of Lord Vishnu, a symbol of mighty power.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* =================================================== */}
                <div className='creation-process'>
                    <div className='container-fluid'>
                        <h1 className='behind-content'>Step behind the scenes and witness the remarkable creation process of "Srinivasa Kalyanam" by Aanvikshiki.</h1>
                        <p className='making-craft'>The making of this divine artwork is as much a ritual as it is a craft, involving a series of intricate steps that blend tradition with the pure passion of artistry.</p>
                        <div className='row'>
                            <div className='col-md-6'>
                                <div className='artist-box'>
                                    <h1 className='craft-head'>The Artistry and Craftsmanship of Aanvikshiki</h1>
                                    <p className='crafted-para'>Crafted under the deft hands of Mr. A.R. Muthukumar and his seasoned team, the painting is not merely an artwork but a conduit of the sacred. With decades of experience, they've woven a tapestry that beckons viewers to immerse in its narrative—one that has been revered for ages.</p>
                                </div>
                                <div className='artist-box'>
                                    <h1 className='craft-head'>The Spiritual and Aesthetic Splendor</h1>
                                    <p className='crafted-para'>Ornate with 22carat gold foil and vibrant pigments, the artwork transcends its physical form, inviting viewers to partake in the divine celebration. It reflects not just the opulence of the celestial wedding but the essence of devotion and the sanctity of vows.</p>
                                </div>
                                <div className='artist-box'>
                                    <h1 className='craft-head'>Elevate Your Space with Divine Blessings</h1>
                                    <p className='crafted-para'>This masterpiece is a beacon of spiritual elegance that promises to sanctify any space it graces. Whether in a pooja room or a grand hall, it brings with it blessings of prosperity, harmony, and divine grace. It's an investment that offers an enduring legacy of beauty, a testament to the timeless love story of Srinivasa Kalyanam.</p>
                                </div>
                                <div className='artist-box'>
                                    <h1 className='craft-head'>A Testament to Faith and Tradition</h1>
                                    <p className='crafted-para'>Owning "Srinivasa Kalyanam: A Celestial Confluence in Tanjore Art by Aanvikshiki" is not just an acquisition of art; it's an embrace of faith, a celebration of cultural richness, and an acknowledgment of spiritual depth. It is an offering from Aanvikshiki that enables you to hold a fragment of the divine in your life and surroundings.</p>
                                </div>
                            </div>
                            <div className='col-md-6'>
                                <img className="kalyanam-image"
                                    src="./images/landing/srinivasa/kalyanam.png"
                                    alt="balaji"
                                />
                            </div>
                        </div>


                    </div>
                </div>
                {/* ==================================================================== */}
                <div className="srinivasa-painting">
              
                <div className='container'>
                <Swiper
                     ref={swiperRef2}
                     navigation={{
                         prevEl: '#customPrevButton2',
                         nextEl: '#customNextButton2'
                     }}
                     className="mySwiper swiper-section-top"
                     pagination={false}
                     loop={true}
                     spaceBetween={30}
                     // slidesPerView={2}
                     centeredSlides={false} // Center the active slide
                     autoplay={{
                       delay: 2500,
                       disableOnInteraction: false,
                       pauseOnMouseEnter: true,
                       waitForTransition: true,
                     }}
                     speed={1000}
                     breakpoints={{
                       640: {
                         slidesPerView: 1,
                       },
                       768: {
                         slidesPerView: 1,
                       },
                       1024: {
                         slidesPerView: 2,
     
                       },
                     }}
                     modules={[Pagination, Navigation, Autoplay]}
                >
                    <SwiperSlide>
                    <div className="card card-box">
              <div className="card-main">
                  <img
                      src="./images/landing/srinivasa/sk1.png"
                      className="card-img-top recom-img"
                      alt="..."
                  />
              </div>
          </div>
                        </SwiperSlide>
              
                        <SwiperSlide>
                    <div className="card card-box">
              <div className="card-main">
                  <img
                      src="./images/landing/srinivasa/sk2.png"
                      className="card-img-top recom-img"
                      alt="..."
                  />
              </div>
          </div>
                        </SwiperSlide>
                        <SwiperSlide>
                    <div className="card card-box">
              <div className="card-main">
                  <img
                      src="./images/landing/srinivasa/sk3.jpeg"
                      className="card-img-top recom-img"
                      alt="..."
                  />
              </div>
          </div>
                        </SwiperSlide>
                
                    
                </Swiper>
                <div className="btns-below">
                    <button id="customPrevButton2" className="next-btn" onClick={handlePrev2}>
                        <i class="fa-solid fa-arrow-left arrow-l"></i>Previous
                    </button>
                    <button id="customNextButton2" className="next-btn" onClick={handleNext2}>
                        Next<i class="fa-solid fa-arrow-right arrow-r"></i>
                    </button>
                </div>
            </div>
                </div>
                {/* ============================================================ */}
                <div className='canvas-cntent'>
                    <div className='container-fluid'>
                        <div className='row'>
                            <div className='col-md-6'>
                                <p className='canvas-para'><span className='sub-canvas'>Canvas Preparation:</span> Our journey begins with the canvas, the sacred ground for our masterpiece. A carefully selected piece of cloth is stretched over a wooden base, creating the perfect taut surface. This canvas is then coated with a layer of chalk powder and a binding medium, creating a smooth arena for our artists to cast their magic.</p>
                                <p className='canvas-para'><span className='sub-canvas'>Sketching the Divine:</span> Sketching the Divine: With precision and devotion, the sketching commences. The sacred figures of Lord Srinivasa and Goddess Padmavati, along with the assembly of deities and sages, are drawn with careful attention to the sacred texts and iconography. Each line is a stroke of reverence, etching the celestial story onto the canvas.</p>
                                <p className='canvas-para'><span className='sub-canvas'>Embellishments that Dazzle:</span>  The hallmark of Tanjore paintings is the embellishment. Using a mixture of chalk powder and a binding medium, the artist creates a raised, 3D effect on the adornments of the deities. This relief work sets the stage for the opulent 22carat gold foil that will soon cloak the gods and goddesses, bringing a divine glow that transcends time.</p>
                                <p className='canvas-para'><span className='sub-canvas'>Layering Colours of Devotion:</span> Natural pigments and vibrant colors are meticulously applied, bringing the sketched figures to life. Each hue is selected to reflect the traditional palette, with reds, greens, and blues dominating the scene, symbolizing the gods' power, prosperity, and tranquillity.</p>
                                <p className='canvas-para'><span className='sub-canvas'>Gilding with Gold:</span>  In a delicate and sacred process, gold leaves are applied to the embellishments. These gold leaves are not just elements of decoration but represent the ethereal glow of the divine, making the deities shine with an otherworldly light.</p>
                            </div>
                            <div className='col-md-6'>
                                <p className='canvas-para1'><span className='sub-canvas'>Gemstone Inlays for Eternity:</span> Authentic Jaipur gemstones, each with a spiritual significance, are then inlaid into the artwork. These jewels add not only beauty but are believed to attract positive energies, making the artwork a source of auspiciousness.</p>
                                <p className='canvas-para1'><span className='sub-canvas'>Protective Coating for Preservation:</span> The artwork is then sealed with a protective coat that ensures its longevity. This coat shields the painting from the dust and humidity of the earthly realms, preserving its sanctity and splendour.</p>
                                <p className='canvas-para1'><span className='sub-canvas'>Framing the Celestial:</span> Finally, the painting is framed with exquisite Chettinad teak wood, known for its durability and elegance. The frame not only adds to the visual grandeur but also protects the painting, offering a window into the divine that stands the test of time.</p>
                                <p className='canvas-para11'><span className='sub-canvas'>Final Blessings:</span> Each "Srinivasa Kalyanam" artwork is concluded with a final blessing, a tradition that imbues the artwork with good wishes and divine favor.</p>
                                <p className='canvas-para1'>This elaborate process, from the first stroke to the last embellishment, is a sacred dance of creativity and devotion. The "Srinivasa Kalyanam" by Aanvikshiki isn't merely an art piece; it's a slice of heaven, a moment of divine love captured forever in color and gold, ready to bless your space with its timeless story and impeccable beauty.</p>
                            </div>
                        </div>

                    </div>
                </div>
                {/* ====================================================================== */}



                
            </div>

        </>
    )
}
export default SrinivasaKalyanam




