import React, { useEffect, useRef, useState } from "react";
import { NavLink } from "react-router-dom";
import Axios from "axios";
import "react-loading-skeleton/dist/skeleton.css";

// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
const Poojaroomdata = () => {

    const [activeIndex, setActiveIndex] = useState(null);

    const toggleAccordion = (index) => {
        setActiveIndex(activeIndex === index ? null : index);
    };


    useEffect(() => {
        const script = document.createElement('script');
        script.src = './js/home.js';
        script.async = true;
        document.body.appendChild(script);
    
        return () => {
          document.body.removeChild(script);
        };
      }, []);
    

    return (
        <>
            <div className="PoojaStore">
                
                               <div className="store">
                    <div className="pooja-image">
                        <div className="pooja-content">
                            <h1 className="poojarom-designs">POOJA ROOM DESIGNERS</h1>
                        </div>
                    </div>
                    <div className="pooja-home">
                        <div className="container">
                            <p className="pooja-text"> At Aanvikshiki, our journey in the realm of art spans a glorious decade, marked by passion, experience, and an unwavering commitment to creating masterpieces that transcend the ordinary. As pioneers in the art industry, we take pride in infusing every creation with soul, divinity, and faith.</p>
        <h1 className="ann-crafting">Crafting Sacred Spaces - Personalized Pooja Rooms</h1>
                            <p className="pooja-text">Aanvikshiki specializes in helping you curate the most divine and personalized sacred space within your home - the Pooja Room. No matter the size or layout of your home, there is always a place for the love of the Gods. Pooja rooms hold a special significance as the most respectable and sacred corners in any home.</p>
                            <p className="pooja-text">Our team of dedicated Pooja room designers in Bangalore, with over 40 years of combined experience, understands the importance of creating a space that exudes tranquility and positivity. We believe that a Pooja Room is not just a physical space; it is a spiritual haven where the divine graces your home.</p>
                            <h1 className="ann-crafting">Why Choose Aanvikshiki for Your Pooja Room Designs?</h1>
                            <p className="pooja-text"><span className="annvikishiki-passion"><span className="num">1.</span>Passion and Precision:</span> Our artists are driven by passion and dedicated to infusing every design with love and precision. Each Pooja Room is crafted with meticulous attention to detail.</p>
                            <p className="pooja-text"><span className="annvikishiki-passion"><span className="num">2.</span>Customization:</span> We understand that every individual has unique preferences when it comes to their sacred space. Our Pooja room designs are tailored to your specific tastes, ensuring a personalized and meaningful representation of your devotion.</p>
                            <p className="pooja-text"><span className="annvikishiki-passion"><span className="num">3.</span>Positive Vibes:</span>Our designs emanate a calm and positive vibe, creating an atmosphere where the divine presence is felt. We believe in the transformative power of a well-designed Pooja Room.</p>
                            <p className="pooja-text"><span className="annvikishiki-passion"><span className="num">4.</span>Materials Expertise:</span> With a variety of materials at our disposal, our Pooja room designers in Bangalore can guide you in choosing the best materials for your Pooja unit. We combine knowledge and creativity to deliver exceptional results.</p>
                            <p className="pooja-text">Your Pooja Room is not just a physical space; it is a reflection of your devotion and spirituality. At Aanvikshiki, we take pride in being skilled artisans who understand the significance of this sacred space. Let us collaborate to design a Pooja Room that is not only visually stunning but also spiritually uplifting. Welcome to Aanvikshiki.art, where we transform spaces into divine sanctuaries.</p>
                            
                        </div>
                    </div>

                    <h1 className="faq-aboutpooja">FAQS ABOUT POOJA ROOM DESIGNS</h1>
                    <div className="accordion">
                        <div className="accordion-item">
                            <div className="accordion-item-header">1. How does Aanvikshiki approach the customization of Pooja Rooms?</div>
                            <div className="accordion-item-body">
                                <div className="accordion-item-body-content">
                                - Aanvikshiki takes a personalized approach, understanding your preferences, beliefs, and the available space. Our experienced team works closely with you to design a Pooja Room that reflects your spirituality and aesthetic taste.
                                </div>
                            </div>
                        </div>
                        <div className="accordion-item">
                            <div className="accordion-item-header">2. What sets Aanvikshiki apart in the creation of Pooja Rooms?</div>
                            <div className="accordion-item-body">
                                <div className="accordion-item-body-content">
                                - Aanvikshiki stands out due to our decade-long legacy in the art industry. Our artists, with over 40 years of combined experience, infuse each Pooja Room design with soul, divinity, and faith. We prioritize creating a sacred space that goes beyond mere aesthetics.
                                </div>
                            </div>
                        </div>
                        <div className="accordion-item">
                            <div className="accordion-item-header">
                            3. Can Aanvikshiki work with different design styles for Pooja Rooms?
                            </div>
                            <div className="accordion-item-body">
                                <div className="accordion-item-body-content">
                                - Yes, absolutely. Aanvikshiki understands that every individual has unique preferences. Whether you prefer traditional, contemporary, or a fusion of styles, our team is skilled in creating Pooja Rooms that align with your taste and blend seamlessly with your home decor.
                                </div>
                            </div>
                        </div>
                        <div className="accordion-item">
                            <div className="accordion-item-header">4. What materials does Aanvikshiki use in crafting Pooja Rooms?</div>
                            <div className="accordion-item-body">
                                <div className="accordion-item-body-content">
                                - Aanvikshiki utilizes a variety of high-quality materials, including wood, marble, metals, and more, depending on your preferences. Our commitment to quality ensures that the materials used are durable, aesthetically pleasing, and resonate with the spiritual essence of the Pooja Room.
                                </div>
                            </div>
                        </div>
                        <div className="accordion-item">
                            <div className="accordion-item-header">5. How long does it take for Aanvikshiki to complete the customization of a Pooja Room?</div>
                            <div className="accordion-item-body">
                                <div className="accordion-item-body-content">
                                - The timeline varies depending on the complexity of the design and materials chosen. Aanvikshiki strives to complete projects efficiently without compromising on the precision and attention to detail. Our team will provide you with a realistic timeline during the consultation phase.
                                </div>
                            </div>
                        </div>
                        <div className="accordion-item">
                            <div className="accordion-item-header">6. Can Aanvikshiki incorporate specific religious or cultural elements into the Pooja Room design?</div>
                            <div className="accordion-item-body">
                                <div className="accordion-item-body-content">
                                - Yes, Aanvikshiki specializes in incorporating religious and cultural elements into the Pooja Room design. Whether it's specific symbols, scriptures, or traditional motifs, our team ensures that the design resonates with your beliefs and cultural background.
                                </div>
                            </div>
                        </div>
                        <div className="accordion-item">
                            <div className="accordion-item-header">7. Is it possible to modify an existing Pooja Room with Aanvikshiki's services?</div>
                            <div className="accordion-item-body">
                                <div className="accordion-item-body-content">
                                - Absolutely. Aanvikshiki offers both new Pooja Room designs and modifications to existing ones. Our team can work with your current space, making enhancements or alterations to create a more personalized and spiritually enriching environment.
                                </div>
                            </div>
                        </div>
                        <div className="accordion-item">
                            <div className="accordion-item-header">8. How does the consultation process work with Aanvikshiki?</div>
                            <div className="accordion-item-body">
                                <div className="accordion-item-body-content">
                                - Aanvikshiki begins with an in-depth consultation to understand your vision, requirements, and preferences. This collaborative process ensures that the final Pooja Room design aligns seamlessly with your expectations and enhances the spiritual ambiance of your home.
                                </div>
                            </div>
                        </div>
               
                    </div>
                </div>
            </div>
        </>
    )
}
export default Poojaroomdata