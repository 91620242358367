import React from "react";
import { NavLink } from "react-router-dom";

const Footer = ({ scrollToTarget }) => {
  // const sectionRef = useRef(null);

  const handleFooterClick = () => {
    ;
    // Scroll to the target element
    if (scrollToTarget && scrollToTarget.current) {
      scrollToTarget.current.scrollIntoView({ behavior: "smooth" });
    }
  };

  return (
    <>
      <div className="footer-content">
        <div className="footer-main-content">
          <div className="container">
            <div className="row justify-content-between">
              <div className="col-md-8">
                <div className="row">
                  <div className="col-md-4">
                    <div className="footer-accounts">
                      <h3 className="footer-title">My Account</h3>
                      <ul className="footer-lists">
                        <li className="footer-insidelists">
                          <NavLink className="btn footer-btns">Profile</NavLink>
                        </li>
                        <li className="footer-insidelists">
                          <NavLink className="btn footer-btns" to="/wishlist">
                            Favorites
                          </NavLink>
                        </li>
                        <li className="footer-insidelists">
                          <NavLink className="btn footer-btns">
                            My Collections
                          </NavLink>
                        </li>
                        <li className="footer-insidelists">
                          <NavLink className="btn footer-btns" to="/settings">
                            Settings
                          </NavLink>
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="footer-accounts">
                      <h3 className="footer-title">Info</h3>
                      <ul className="footer-lists">
                        <li className="footer-insidelists">
                          <NavLink className="btn footer-btns">
                            How to Pay
                          </NavLink>
                        </li>
                        <li className="footer-insidelists">
                          <NavLink className="btn footer-btns">FAQs</NavLink>
                        </li>
                        <li className="footer-insidelists">
                          <NavLink className="btn footer-btns">
                            Delivery Info
                          </NavLink>
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="footer-accounts">
                      <h3 className="footer-title">Company</h3>
                      <ul className="footer-lists">
                        <li className="footer-insidelists">
                          <NavLink
                            className="btn footer-btns"
                            onClick={handleFooterClick}
                          >
                            About us
                          </NavLink>
                        </li>
                        <li className="footer-insidelists">
                          <NavLink className="btn footer-btns">
                            Contact us
                          </NavLink>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-4">
                <div className="social-main">
                  <ul className="social-data-main">
                    <li className="inside-social">
                      <a className="btn social-data" target="_blank" href="https://twitter.com/AanvikshikiArt/">
                      <i class="fa-brands fa-x-twitter"></i>
                      </a>
                    </li>
                    <li className="inside-social">
                      <a className="btn social-data" target="_blank" href="https://www.instagram.com/aanvikshiki.art/">
                        <i class="fab fa-instagram icon-data"></i>
                      </a>
                    </li>
                    <li className="inside-social">
                      <a className="btn social-data" target="_blank" href="https://www.facebook.com/aanvikshiki.art/">
                        <i class="fab fa-facebook-f icon-data"></i>
                      </a>
                    </li>
                    <li className="inside-social">
                      <a className="btn social-data" target="_blank" href="https://www.youtube.com/channel/UCu9df4dmcIidlJYwTeMcrEQ">
                        <i class="fab fa-youtube icon-data"></i>
                      </a>
                    </li>
                    <li className="inside-social">
                      <a className="btn social-data" target="_blank" href="https://www.linkedin.com/company/aanvikshiki-art/">
                      <i class="fa-brands fa-linkedin-in"></i>
                      </a>
                    </li>
                    <li className="inside-social">
                      <a className="btn social-data" target="_blank" href="https://medium.com/@aanvikshikiart/">
                      <i class="fab fa-medium-m"></i>
                      </a>
                    </li>
                  </ul>
                  <p className="copyrights-para">Copyright © 2022. Aanvikshiki Arts</p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="copyrights-data">
          
          <p className="copyrights-para">Created<img
                            className="image-love"
                            src="/images/landing/love.gif"
                            alt="no img"
                          />by Chakravuyha Team</p>
        </div>
      </div>
    </>
  );
};

export default Footer;
