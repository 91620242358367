import React, { useEffect, useRef } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';

import "react-loading-skeleton/dist/skeleton.css";
import Axios from "axios";
import toastr from "toastr";
import 'toastr/build/toastr.css';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css'; // Import the styles
// Import Swiper React components
// import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";

import { NavLink } from 'react-router-dom';
// Import Swiper styles
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';

import { Autoplay } from "swiper/modules";
// import required modules
import { Pagination, Navigation } from 'swiper/modules';

const Balajipadmavathi = () => {
    const swiperRef1 = useRef(null);

    const handleNext1 = () => {
        if (swiperRef1.current && swiperRef1.current.swiper) {
            swiperRef1.current.swiper.slideNext();
        }
    };

    const handlePrev1 = () => {
        if (swiperRef1.current && swiperRef1.current.swiper) {
            swiperRef1.current.swiper.slidePrev();
        }
    };


    useEffect(() => {
        window.scroll({
            top: 0,
            left: 0,
            behavior: "smooth",
        });
    }, []);
    return (
        <>
            <div className='balaji-padmavathi-main'>
                <div className='padmavathi-content'>
                    <h1 className='immerse-head'>Immerse Yourself in the Divine Splendour of Balaji & Padmavati Aanvikshiki Tanjore Painting</h1>
                    <p className='alimeru-data'>Step into the sacred ambiance of the Tiruchanur Alimelu Manga Temple and the holy Tirumala as the Aanvikshiki Tanjore Painting of Balaji and Padmavati captures their divine reflections with an artistry that transcends time. This painting is a gateway to the divine, mirroring the celestial beauty of the deities as revered in their holy abodes.</p>
                </div>
                <div className='padamavthi-video'>
                    <div className='container-fluid'>
                        <div className='row'>
                            <div className='col-md-6'>
                                <img
                                    src="./images/balaji/main.jpg"
                                    className="card-img-top padmavathi-img"
                                    alt="..."
                                />
                            </div>
                            <div className='col-md-6'>
                                <div className='divine-content'>
                                    <h1 className='divines-head'>Discover the Divine Essence of Aanvikshiki Tanjore Paintings</h1>
                                    <p className='realm-para'>In the realm of traditional art, Aanvikshiki's collection of Tanjore paintings emerges as a source of divine inspiration and cultural heritage. Each masterpiece, crafted under the guidance of the illustrious Mr. A.R. Muthukumar and his team of exceptional artisans, transcends the boundaries of conventional artistry. These creations invite you into a world rich in myth, tradition, and spiritual enlightenment, celebrating the timeless stories and deities that form the core of our heritage.</p>
                                    <h1 className='divines-head'>A Testament to Masterful Artistry</h1>
                                    <p className='realm-para'>Mr. A.R. Muthukumar and his adept team are visionaries in their field, breathing life into each stroke and color with a profound mastery over the human anatomy. Their work captures the divine essence embedded in the rich tapestry of Tanjore art. Every painting in the Aanvikshiki collection is a testament to their unparalleled skill and dedication to perfection.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
<div className='balaji-art'>
<h1 className='art-head'>Divine Reflections in Art: The Balaji & Padmavati Aanvikshiki Tanjore Painting</h1>
<div className='container-fluid'>
                        <div className='row'>
                      
                            <div className='col-md-6'>
                                <div className='art-contents'>
                                    <h1 className='arts-head'>Artistic Revelation of Sacred Serenity</h1>
                                    <p className='arts-para'>The Balaji & Padmavati Tanjore Painting stands as a homage to the original idols, crafted with a devotion that breathes life into pigments and gold. As your gaze falls upon Lord Balaji, it's as if you're transported to the serene sanctity of Tirumala, where his presence commands reverence and adoration. The elaborate adornments, meticulously laid with genuine gold foil, shimmer with the same spiritual energy that one feels in the hallowed halls where Balaji resides.</p>
                                    <h1 className='arts-head'>A Mirrored Image of Divine Grace</h1>
                                    <p className='arts-para'>Goddess Padmavati is depicted with an elegance that reflects her abode at Tiruchanur Alimelu Manga Temple. Each line and curve are a tribute to her grace, her compassionate eyes a window to divine love. The painting’s radiant colours and intricate embellishments resonate with the splendour of Padmavati, echoing the very soul of devotion that permeates her temple.</p>
                                    <h1 className='arts-head'>A Confluence of Craftsmanship and Devotion</h1>
                                    <p className='arts-para'>The artists of Aanvikshiki, led by the venerable Mr. A.R. Muthukumar, approach their craft not just as artisans but as devotees. Their hands work in sacred meditation, imbuing the canvas with the essence of Balaji and Padmavati's divine narratives. The ornate details, from the opulent throne to the vibrant floral garlands, are not merely decorative elements but a visual mantra that recites the glory of the deities.</p>
                                    <h1 className='arts-head'>Embrace the Realistic Reflections of Divinity</h1>
                                    <p className='arts-para'>With this Tanjore painting, you welcome a piece of Tirupati into your space, a daily darshan that transcends the physical journey. It is an invitation to experience a slice of divine majesty, a realistic portrayal that brings the sanctity and blessedness of Balaji and Padmavati into your home.</p>
                                    <h1 className='arts-head'>A Legacy of Sacred Art</h1>
                                    <p className='arts-para'>The Aanvikshiki Tanjore Painting of Balaji and Padmavati is not only a masterpiece of traditional art; it is a reverent representation of holy visions, offering an opportunity to cherish and worship the divine duo as they are in their revered temples. It is an artistic conduit to the sacred, a touchstone of spirituality that graces the lives of those who behold it.</p>
                                  
                                </div>
                            </div>
                            <div className='col-md-6'>
                            <div className='videos'>
                        <iframe className='video-load' src="https://www.youtube.com/embed/O-SSdnqQ68M" title="Aanvikshiki Presents: Vakulamatha Unveils the Tale of Srinivasa Kalyanam | Hindi" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
                                </div>
                            </div>
                        </div>
                    </div>
</div>
                <div className="balaji-painting">
                    <div className='container'>
                        <Swiper
                            ref={swiperRef1}
                            navigation={{
                                prevEl: '#customPrevButton2',
                                nextEl: '#customNextButton2'
                            }}
                            className="mySwiper swiper-section-top"
                            pagination={false}
                            loop={false}
                            spaceBetween={30}
                            // slidesPerView={2}
                            centeredSlides={false} // Center the active slide
                            autoplay={{
                                delay: 2500,
                                disableOnInteraction: false,
                                pauseOnMouseEnter: true,
                                waitForTransition: true,
                            }}
                            speed={1000}
                            breakpoints={{
                                640: {
                                    slidesPerView: 1,
                                },
                                768: {
                                    slidesPerView: 1,
                                },
                                1024: {
                                    slidesPerView: 2,

                                },
                            }}
                            modules={[Pagination, Navigation, Autoplay]}
                        >
                            <SwiperSlide>
                                <div className="balaji-card balaji-card-box">
                                    <div className="balaji-card-main">
                                        <img
                                            src="./images/balaji/slide1.png"
                                            className="card-img-top balaji-recom-img"
                                            alt="..."
                                        />
                                    </div>
                                </div>
                            </SwiperSlide>

                            <SwiperSlide>
                                <div className="card balaji-card-box">
                                    <div className="balaji-card-main">
                                        <img
                                            src="./images/balaji/slide2.png"
                                            className="card-img-top balaji-recom-img"
                                            alt="..."
                                        />
                                    </div>
                                </div>
                            </SwiperSlide>
                            <SwiperSlide>
                                <div className="card card-box">
                                    <div className="balaji-card-main">
                                        <img
                                            src="./images/balaji/slide3.png"
                                            className="card-img-top balaji-recom-img"
                                            alt="..."
                                        />
                                    </div>
                                </div>
                            </SwiperSlide>
                            <SwiperSlide>
                                <div className="card card-box">
                                    <div className="balaji-card-main">
                                        <img
                                            src="./images/balaji/slide4.png"
                                            className="card-img-top balaji-recom-img"
                                            alt="..."
                                        />
                                    </div>
                                </div>
                            </SwiperSlide>
                            {/* <SwiperSlide>
                  <div className="card card-box">
            <div className="balaji-card-main">
                <img
                    src="./images/balaji/slide5.png"
                    className="card-img-top balaji-recom-img"
                    alt="..."
                />
            </div>
        </div>
                      </SwiperSlide>     */}
           
                            <SwiperSlide>
                                <div className="card card-box">
                                    <div className="balaji-card-main">
                                        <img
                                            src="./images/balaji/slide7.png"
                                            className="card-img-top balaji-recom-img"
                                            alt="..."
                                        />
                                    </div>
                                </div>
                            </SwiperSlide>
                            <SwiperSlide>
                  <div className="card card-box">
            <div className="balaji-card-main">
                <img
                    src="./images/balaji/main.jpg"
                    className="card-img-top balaji-recom-img"
                    alt="..."
                />
            </div>
        </div>
                      </SwiperSlide>  
                        </Swiper>
                        <div className="btns-below">
                            <button id="customPrevButton2" className="next-btn" onClick={handlePrev1}>
                                <i class="fa-solid fa-arrow-left arrow-l"></i>Previous
                            </button>
                            <button id="customNextButton2" className="next-btn" onClick={handleNext1}>
                                Next<i class="fa-solid fa-arrow-right arrow-r"></i>
                            </button>
                        </div>
                    </div>
                </div>

                <div className='divine-tale'>
                    <div className='container-fluid'>
                       
                        <h1 className='grace-head'>Crafting Divinity: The Balaji & Padmavati Aanvikshiki Tanjore Painting</h1>
                        <p className='vakula'>Embark on a journey through the sacred artistry of the Balaji & Padmavati Aanvikshiki Tanjore Painting, where every brushstroke is a testament to divine adoration and meticulous craftsmanship.</p>
                        <div className='row'>
                            <div className='col-md-6'>
                                <div className='content-list'>
                                    <h1 className='canvas-head'>Canvas Preparation: A Sacred Foundation</h1>
                                    <p className='canvas-data'>Our artistic voyage begins with a carefully chosen cloth, meticulously stretched over a bespoke wooden frame. It is here, upon this taut canvas, that our journey from inspiration to creation takes form, each layer of chalk powder and binding medium setting the stage for a tale of celestial love to unfold.</p>
                                    <h1 className='canvas-head'>Sketching the Divine: Portraits of Devotion</h1>
                                    <p className='canvas-data'>With the grace of a temple painter, our artists render the forms of Lord Balaji and Goddess Padmavati, their divine silhouettes emerging from the canvas, ready to be adorned with the spiritual vibrancy they are revered for. This act of sketching goes beyond mere outlines—it is a ritual, honoring the timeless saga of divine companionship.</p>
                                    <h1 className='canvas-head'>Embellishments that Dazzle: The Art of Elevation</h1>
                                    <p className='canvas-data'>The hallmark of Tanjore paintings—elevated adornments—takes shape as the artist meticulously sculpts a mixture of chalk powder and medium to create a three-dimensional tableau. This stage sets the scene for the opulent gold leafing, ensuring that the divine figures of Balaji & Padmavati are arrayed in splendor worthy of their celestial standing.</p>
                                    <h1 className='canvas-head'>Layering Colours of Devotion: A Spectrum of Faith</h1>
                                    <p className='canvas-data'>Hand-ground natural pigments are tenderly applied to give life to the deities, with hues chosen to echo the sacred colors typically seen within the inner sanctums of Balaji and Padmavati's temples. These vibrant colors symbolize the depth of their narratives, the power of their blessings, and the serenity of their divine presence.</p>
                                    <h1 className='canvas-head'>Gilding with Gold: Lasting Radiance</h1>
                                    <p className='canvas-data'>In a meditative process, gold leaves are lovingly affixed to the deities' visages and ornaments, not merely for ornamentation but as a representation of the eternal light they emanate. The glimmer of gold transforms the painting into a living piece of devotion, reflecting the heavenly glow of Balaji & Padmavati.</p>
                                    
                                </div>
                            </div>
                            <div className='col-md-6'>
                                <div className='content-list1'>
                                <h1 className='canvas-head1'>Gemstone Inlays for Eternity: Jewels of Worship</h1>
                                    <p className='canvas-data1'>Exquisite Jaipur gemstones, each bearing spiritual significance, are intricately set within the artwork. These precious stones enhance the beauty and sanctity of the deities, drawing the viewer into a space of reverence and mystique.</p>
                                <h1 className='canvas-head1'>Protective Coating for Preservation: Guarding the Divine</h1>
                                    <p className='canvas-data1'>A protective coating is applied to seal the artwork, safeguarding the divine depictions from the elements of time. This layer preserves the sanctity and splendor of the painting, allowing it to be a source of inspiration for generations.</p>
                                <h1 className='canvas-head1'>Framing the Celestial: A Window to Divinity</h1>
                                    <p className='canvas-data1'>The finished painting is framed with the finest Chettinad teak wood, known for its robust beauty and durability. This frame not only complements the artwork's majesty but also serves as a protective portal, offering viewers a glimpse into the divine cosmos.</p>
                                <h1 className='canvas-head1'>Final Blessings: A Consecration</h1>
                                    <p className='canvas-data1'>Each Balaji & Padmavati painting concludes with a final blessing, infusing the artwork with wishes of prosperity and divine favor—a tradition that encapsulates the essence of Aanvikshiki's commitment to spiritual artistry.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>



        </>
    )
}

export default Balajipadmavathi