import "./App.css";
import Layout from "./Pages/Layout/Layout";
import Landing from "./Pages/Landing/Landing";
import { Route, Routes } from "react-router-dom";
import Pigmentprinting from "./Pages/Pigmentprinting/Pigmentprinting";
import Tirupatigoldpainting from "./Pages/Tirupatigoldpainting/Tirupatigoldpainting";
import Ai from "./Pages/Ai/Ai";
import Modernframes from "./Pages/Modernframes/Modernframes";
import Innerpage from "./Pages/Innerpage/Innerpage";
import Wishlistpage from "./Pages/Wishlistpage/Wishlistpage";
import Addtobag from "./Pages/Addtobag/Addtobag";
import Settings from "./Pages/Settings/Settings";
import Orderpage from "./Pages/Orderpage/Orderpage";
import Poojaroomdata from "./Pages/Poojaroom/Poojaroomdata";
import Handpainting from "./Pages/handpainting/Handpainting";
import SrinivasaKalyanam from "./Pages/SrinivasaKalyanam";
import Balajipadmavathi from "./Pages/Balajipadmavathi/Balajipadmavathi";



function App() {
  return (
    <>
      <Routes>
        <Route path="/" element={<Layout />}>
          <Route index element={<Landing />} />
          <Route path="/landing" element={<Landing />} />
          <Route path="/printing" element={<Pigmentprinting />} />
          <Route path="/frames/:id" element={<Tirupatigoldpainting />} />
          <Route path="/frames/:id" element={<Ai />} />
          <Route path="/modern-frames" element={<Modernframes />} />
          <Route path="/card-inner/:id" element={<Innerpage />} />
          <Route path="/wishlist" element={<Wishlistpage />} />
          <Route path="/bag" element={<Addtobag />} />
          <Route path="/Settings" element={<Settings />} />
          <Route path="/myorder" element={<Orderpage />} />
          <Route path="/PoojaRoom" element={<Poojaroomdata />} />
          <Route path="/Tanjore-painting/:id" element={<Handpainting />} />
          <Route path="/SrinivasaKalyanam" element={<SrinivasaKalyanam />} />
          <Route path="/Balajipadmavati" element={<Balajipadmavathi />} />
        </Route>
        {/* <Route path="/login" element={<Login />} /> */}
      </Routes>
    </>
  );
  
}

export default App;
