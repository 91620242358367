import React from "react";
import { NavLink } from "react-router-dom";

const Modernframes = () => {
  return (
    <>
      <div className="pigmentprinting-content">
        <div className="pigmentprinting-main">
          <div className="container">
            <h3 className="page-title">
              <NavLink className="page-titlespan" to="/landing">
                {" "}
                Home /{" "}
              </NavLink>{" "}
              MODERN FRAMES
            </h3>
            <ul className="filter-top">
              <li>Filter</li>
              <li className="total-products">2233 products</li>
              <li>Relevance</li>
            </ul>
            <div className="pigmentlists-data">
              <div className="row">
                <div className="col-md-3">
                  <div className="card-contents-inside">
                    <div className="card card-inside">
                      <img
                        className="card-images"
                        src="./images/landing/balaji.png"
                        alt="no-data"
                      />
                    </div>
                    <div className="card-body card-body-inside">
                      <p className="card-title insidecard-balajititle">
                        Lord Venkateshwara (Tirupati Balaji)
                      </p>
                      <p className="card-text insidecard-balajipara">
                        27.00 INCH HEIGHT X 21.00 INCH WIDTH
                      </p>
                      <h5 className="price-card">₹2,500</h5>
                    </div>
                  </div>
                </div>
                <div className="col-md-3">
                  <div className="card-contents-inside">
                    <div className="card card-inside">
                      <img
                        className="card-images"
                        src="./images/landing/lakshmi-balaji.png"
                        alt="no-data"
                      />
                    </div>
                    <div className="card-body card-body-inside">
                      <p className="card-title insidecard-balajititle">
                        Lord Venkateshwara (Tirupati Balaji)
                      </p>
                      <p className="card-text insidecard-balajipara">
                        27.00 INCH HEIGHT X 21.00 INCH WIDTH
                      </p>
                      <h5 className="price-card">₹2,500</h5>
                    </div>
                  </div>
                </div>
                <div className="col-md-3">
                  <div className="card-contents-inside">
                    <div className="card card-inside">
                      <img
                        className="card-images"
                        src="./images/landing/balaji-1.png"
                        alt="no-data"
                      />
                    </div>
                    <div className="card-body card-body-inside">
                      <p className="card-title insidecard-balajititle">
                        Lord Venkateshwara (Tirupati Balaji)
                      </p>
                      <p className="card-text insidecard-balajipara">
                        27.00 INCH HEIGHT X 21.00 INCH WIDTH
                      </p>
                      <h5 className="price-card">₹2,500</h5>
                    </div>
                  </div>
                </div>
                <div className="col-md-3">
                  <div className="card-contents-inside">
                    <div className="card card-inside">
                      <img
                        className="card-images"
                        src="./images/landing/balaji-2.png"
                        alt="no-data"
                      />
                    </div>
                    <div className="card-body card-body-inside">
                      <p className="card-title insidecard-balajititle">
                        Lord Venkateshwara (Tirupati Balaji)
                      </p>
                      <p className="card-text insidecard-balajipara">
                        27.00 INCH HEIGHT X 21.00 INCH WIDTH
                      </p>
                      <h5 className="price-card">₹2,500</h5>
                    </div>
                  </div>
                </div>
                <div className="col-md-3">
                  <div className="card-contents-inside">
                    <div className="card card-inside">
                      <img
                        className="card-images"
                        src="./images/landing/balaji.png"
                        alt="no-data"
                      />
                    </div>
                    <div className="card-body card-body-inside">
                      <p className="card-title insidecard-balajititle">
                        Lord Venkateshwara (Tirupati Balaji)
                      </p>
                      <p className="card-text insidecard-balajipara">
                        27.00 INCH HEIGHT X 21.00 INCH WIDTH
                      </p>
                      <h5 className="price-card">₹2,500</h5>
                    </div>
                  </div>
                </div>
                <div className="col-md-3">
                  <div className="card-contents-inside">
                    <div className="card card-inside">
                      <img
                        className="card-images"
                        src="./images/landing/lakshmi-balaji.png"
                        alt="no-data"
                      />
                    </div>
                    <div className="card-body card-body-inside">
                      <p className="card-title insidecard-balajititle">
                        Lord Venkateshwara (Tirupati Balaji)
                      </p>
                      <p className="card-text insidecard-balajipara">
                        27.00 INCH HEIGHT X 21.00 INCH WIDTH
                      </p>
                      <h5 className="price-card">₹2,500</h5>
                    </div>
                  </div>
                </div>
                <div className="col-md-3">
                  <div className="card-contents-inside">
                    <div className="card card-inside">
                      <img
                        className="card-images"
                        src="./images/landing/balaji-1.png"
                        alt="no-data"
                      />
                    </div>
                    <div className="card-body card-body-inside">
                      <p className="card-title insidecard-balajititle">
                        Lord Venkateshwara (Tirupati Balaji)
                      </p>
                      <p className="card-text insidecard-balajipara">
                        27.00 INCH HEIGHT X 21.00 INCH WIDTH
                      </p>
                      <h5 className="price-card">₹2,500</h5>
                    </div>
                  </div>
                </div>
                <div className="col-md-3">
                  <div className="card-contents-inside">
                    <div className="card card-inside">
                      <img
                        className="card-images"
                        src="./images/landing/balaji-2.png"
                        alt="no-data"
                      />
                    </div>
                    <div className="card-body card-body-inside">
                      <p className="card-title insidecard-balajititle">
                        Lord Venkateshwara (Tirupati Balaji)
                      </p>
                      <p className="card-text insidecard-balajipara">
                        27.00 INCH HEIGHT X 21.00 INCH WIDTH
                      </p>
                      <h5 className="price-card">₹2,500</h5>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Modernframes;
